import React from 'react'
import Feature from '../Cards/Feature'

const FeaturesArea = () => {
  return (
    <section className="feature-area pb-70">
        <div className="container">
            <div className="row">
                
                <Feature 
                    icon="flaticon-cyber"
                    title="Dark Web Monitoring"
                    description="Explore cyber threats in Dark Web.
                    ThreatsEye Discover content, insight and trends from the depths of the darknets."
                />
                <Feature 
                    icon="flaticon-security-1"
                    title="Spam Domain Tracking"
                    description="Your website’s domain name is the most important part of your digital identity. Protecting it is worth the investment."
                />

                <Feature 
                    icon="flaticon-database"
                    title="Data Breach Alerting"
                    description="Be the first to catch the leak. ThreatsEye detection system create early alerts with the most powerful data breach feeds."
                />

                <Feature 
                    icon="flaticon-cybercrime"
                    title="Live Cyber Attacks"
                    description="Get real-time information about cyber attacks as they occur."
                />
                
            </div>
        </div>
    </section>
  )
}

export default FeaturesArea