import React, { useState } from "react";

export default function GoToTop() {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  window.addEventListener("scroll", toggleVisible);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const classNames = ["go-top"];
  if (!visible) classNames.push(" active ");

  return (
    <div className={classNames} onClick={scrollToTop}>
      <i className="bx bx-chevrons-up"></i>
      <i className="bx bx-chevrons-up"></i>
    </div>
  );
}
