import React from "react";
import { Link } from "react-router-dom";

const Service = ({to, ...props}) => {
  return (
    <div className="col-lg-3 col-sm-6">
      <div className="single-services">
        <div className="services-img">
          <Link to={to}>
            <img src={props.image} alt={props.title} loading="lazy"/>
          </Link>
        </div>

        <div className="services-content">
          <h3>
            <Link to={to}>{props.title}</Link>
          </h3>
          <div className="content">
            <p>
              {props.description}
            </p>

            <Link to={to} className="read-more">
              Read More
              <i className="flaticon-right-arrow"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
