import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/Logo/logo.png";
import SingleWidget from "./Footer/SingleWidget";
import FooterDescription from "./Footer/FooterDescription";
import SocialIconContainer from "./Footer/SocialIconContainer";
import SocialIconItems from "./Footer/SocialIconItems";
import FooterContainer from "./Footer/FooterContainer";
import CopyrightAreaContainer from "./Footer/CopyrightAreaContainer";
import FooterLinksContainer from "./Footer/FooterLinksContainer";
import FooterLinksItem from "./Footer/FooterLinksItem";
import AddressContainer from "./Footer/AddressContainer";
import AddressItem from "./Footer/AddressItem";
import CopyrightListContainer from "./Footer/CopyrightListContainer";
import Copyright from "./Footer/Copyright";
import Headlines from "./Footer/Headlines";

const Footer = () => {

 
  return (
    <>
      <FooterContainer>
      
        <div className="col-lg-4 col-md-6">
          <SingleWidget>
            <Link to="/" className="logo">
              <img src={Logo} alt="Logo" />
            </Link>
            <FooterDescription description="Cyber security is a business fundamental in today's world. With criminal digital gangs and unstable politics, the need to protect your data, systems and hardware is growing and only becoming more complex." />

            <SocialIconContainer>
              <SocialIconItems to="/" icon="bx bxl-facebook" />
              <SocialIconItems to="/" icon="bx bxl-instagram" />
              <SocialIconItems to="/" icon="bx bxl-linkedin-square" />
              <SocialIconItems to="/" icon="bx bxl-twitter" />
            </SocialIconContainer>
          </SingleWidget>
        </div>

        <div className="col-lg-4 col-md-6">
          <SingleWidget>
            <Headlines title="Office Location" />
            <AddressContainer>              
              <AddressItem
                icon="bx bxs-envelope"
                title="contact@threatseye.com"
              />
              <AddressItem icon="bx bxs-phone-call" title="+212 537 203 977" />
            </AddressContainer>
          </SingleWidget>
        </div>

        <div className="col-lg-4 col-md-6">
          <SingleWidget>
            <Headlines title="Solutions" />
            <FooterLinksContainer>
              <FooterLinksItem
                to="/data-breach-alerting"
                title="Data Breach Alerting"
              />
              <FooterLinksItem
                to="/dark-web-monitoring"
                title="Dark Web Monitoring"
              />
              <FooterLinksItem
                to="/spam-domain-tracking"
                title="Spam Domain Tracking"
              />
              <FooterLinksItem
                to="/live-cyber-attacks"
                title="Live Cyber Attacks"
              />
            </FooterLinksContainer>
          </SingleWidget>
        </div>
        
      </FooterContainer>

      <CopyrightAreaContainer>
        <div className="col-lg-6 col-md-6">
          <Copyright />
        </div>

        <div className="col-lg-6 col-md-6">
          <CopyrightListContainer>
            <FooterLinksItem to="/privacy-policy" title="Privacy Policy" />
            <FooterLinksItem
              to="/terms-conditions"
              title="Terms & Conditions"
            />
          </CopyrightListContainer>
        </div>
      </CopyrightAreaContainer>
    </>
  );
};

export default Footer;
