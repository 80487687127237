import React from "react";
import PageTitleContent from "../components/Cards/PageTitleContent";
import RequestDemoForm from "../components/Forms/RequestDemoForm";

function RequestDemo() {
  return (
    <>
      <PageTitleContent headline="Request a demo" title="Request a demo" />

      <section className="user-area-style ptb-100">
        <div className="container">
          <div className="contact-form-action">
            <div className="account-title">
              <h2>Information</h2>
            </div>
            <RequestDemoForm />
          </div>
        </div>
      </section>
    </>
  );
}

export default RequestDemo;
