import React from "react";

export default function FooterContainer({children}) {
  return (
    <footer className="footer-area pt-100 pb-70">
      <div className="container">
        <div className="row">{children}</div>
      </div>
    </footer>
  );
}
