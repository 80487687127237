import React from 'react'
import { Link } from 'react-router-dom'

export default function Solution({to, ...props}) {
  return (
    <div className="col-lg-12 col-md-6">
        <div className="single-solution overly-one">
            <div className="overly-two">
                <h3>
                    <Link to={to}>
                        {props.title}
                    </Link>
                </h3>
                <p>{props.description}</p>
                <span>{props.numerotation}</span>
            </div>
        </div>
    </div>
  )
}
