import React from 'react'
import { Link } from "react-router-dom";

export default function NavbarListItems({to, ...props }) {
  return (
    <li className="nav-item">
        <Link to={to} className="nav-link">
            {props.text}
        </Link>
    </li>
  )
}
