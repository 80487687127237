import React from "react";
import PageTitleContent from "../components/Cards/PageTitleContent";
import GetQuote from "../components/Forms/GetQuote";

const Contact = () => {
  
  return (
    <>
      <PageTitleContent headline="Get a Quote" title="Get a Quote" />

      <section className="main-contact-area ptb-100 plr-50">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 offset-lg-2">
              <div className="contact-wrap">
                <div className="contact-form">
                  <div className="contact-title">
                    <h2>Get a Quote</h2>
                  </div>
                  <GetQuote />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
