import React from "react";
import Service from "../Cards/Service";
import Services5 from "../../assets/img/Services/services-5.jpg";
import Services2 from "../../assets/img/Services/services-2.jpg";
import Services3 from "../../assets/img/Services/services-3.jpg";
import Services8 from "../../assets/img/Services/services-8.jpeg";
import SectionTitle from "./SectionTitle";

const Services = () => {
  return (
    <section className="services-area pt-50 pb-70">
      <div className="container">
        <SectionTitle
          title="Solutions"
          description="You Can Ensure Your Organization's Cybersecurity By Using Our Solutions."
        />

        <div className="row">
          <Service
            to="/data-breach-alerting"
            image={Services5}
            title="Data Breach Alerting"
            description=""
          />

          <Service
            to="/dark-web-monitoring"
            image={Services8}
            title="Dark Web Monitoring"
            description=""
          />

          <Service
            to="/spam-domain-tracking"
            image={Services3}
            title="Spam Domain Tracking"
            description=""
          />

          <Service
            to="/live-cyber-attacks"
            image={Services2}
            title="Live Cyber Attacks"
            description=""
          />
        </div>
      </div>
    </section>
  );
};

export default Services;
