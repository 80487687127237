import React from 'react'
import { Link } from 'react-router-dom'
import Image from '../../assets/img/Solutions/solution-details-1.jpg'
import PageTitleContent from '../../components/Cards/PageTitleContent'


const DarkWeb = () => {
  return (
    <>
		<PageTitleContent headline="Dark Web Monitoring" title="Solution Details" />

        <section className="solution-details-area ptb-100">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6">
						<div className="solutions-content">
							<p>Welcome to the Dark Web, a subset of the Internet that you can only access using special software. If you have been following the news, you most likely know that hackers use it to steal large amounts of information and commit crimes. But, there is an upside. We have developed a new technology that monitors dark web activities and informs its clients of any illegal activities they are committing. Our product has been a huge success because it allows companies to get ahead of a potential crisis.
							</p>
							<p>The Dark Web is a shadowy corner of the internet, hidden from search engines and accessible only by Tor. Accessible by hackers, drug dealers and other criminals, Dark Web monitoring is a serious concern. With the latest in professional Deep Web Monitoring software, you can protect your business with 24/7/365 protection that's completely undetectable.
							</p>
							
						</div>
					</div>

					<div className="col-lg-6">
						<div className="solution-details-one">
							<img src={Image} alt="Solution" />
						</div>
					</div>

					<div className="col-lg-12">
						<div className="solutions-content two">
							<h3>Our Protection Tool </h3>
							<p>We have created a web protection tool that monitors your company's internet activities and alerts you when undesirable or dangerous websites are visited. It also blocks the Dark Web, which is a site not accessible to casual browsers. This tool also allows you to set content filters, blacklist websites, and blacklist keywords, so it's perfect for blocking blacklisted content.</p>
						</div>
					</div>

					<div className="col-lg-12">
						<div className="solutions-content three">
							<h3>Monitoring the Dark Web</h3>
							
							<p>Dark Web Monitoring has become a critical aspect of cybersecurity and Internet safety. A single hacker can steal data and exploit networks by exploiting vulnerabilities on their network. One of the ways to invert this risk is by monitoring the Dark Web, where hackers trade information on how to break into your data. We monitor Dark Web sites like Black Hat forums and Shodan, while also scanning them for malware, encryption keys, credit card numbers, and other sensitive data.</p>
						</div>
					</div>

					<div className="col-lg-12">
						<div className="solutions-content four">
							<h3>We provide:</h3>
							<ul>
								<li>
									<i className="bx bx-check-circle"></i>
									Identifying threats
								</li>
								<li>
									<i className="bx bx-check-circle"></i>
									Mitigating cyber security risk
								</li>
								<li>
									<i className="bx bx-check-circle"></i>
									Protecting  malware
								</li>
								<li>
									<i className="bx bx-check-circle"></i>
									Defending  insider threats
								</li>
								<li>
									<i className="bx bx-check-circle"></i>
									Securing remote workers
								</li>
								
							</ul>

							<div className="challenge-btn">
								<Link to="/contact" className="default-btn overly-one">
									<span>Talk to expert</span>
								</Link>

								<Link to="/request-demo" className="default-btn overly-one two">
									<span>Request Demo</span>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    </>
  )
}

export default DarkWeb