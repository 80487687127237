import React from 'react'

export default function DotMenu() {
  return (
    <div className="inner">
        <div className="circle circle-one"></div>
        <div className="circle circle-two"></div>
        <div className="circle circle-three"></div>
    </div>
  )
}
