import React from 'react'

const Feature = ({icon, title, description}) => {
  return (
    <div className="col-lg-3 col-sm-6">
        <div className="single-challenges overly-one">
            <div className="overly-two">
                <div className="title">
                    <i className={icon}></i>
                    <h3>{title}</h3>
                </div>
                <p>{description}</p>
                <span className={icon}></span>
            </div>
        </div>
    </div>
  )
}

export default Feature