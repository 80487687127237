import React from "react";
import Navbar from "./components/Layouts/Navbar";
import Footer from "./components/Layouts/Footer";
import { Routes, Route, useNavigate  } from "react-router-dom";

import Home from "./pages/Home";
import Contact from "./pages/Contact";
import RequestDemo from "./pages/RequestDemo";
import PricvacyPolicy from "./pages/PricvacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import About from "./pages/About";
import Solutions from "./pages/Solutions";
import LiveCyberAttacks from "./pages/Solutions/LiveCyberAttacks";
import DarkWeb from "./pages/Solutions/DarkWeb";
import DataBreach from "./pages/Solutions/DataBreach";
import DomainMonitoring from "./pages/Solutions/DomainMonitoring";
import "./assets/css/animate.min.css";
import "./assets/css/boxicons.min.css";
import "./assets/css/flaticon.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import GoToTop from "./components/Layouts/GoToTop";
import E404 from "./pages/E404";
import GetQuote from "./pages/GetQuote";
import Order from "./pages/Order";
import ThreatsMap from "./pages/ThreatsMap";
import Faq from "./pages/Faq";
import Blog from "./pages/Blog/Blog";
import BlogDetail from "./pages/Blog/BlogDetail";

const App = () => {

  const navigate = useNavigate();

  const handleLiveCyberAttacksClick = () => {
    navigate("/live-cyber-attacks", { replace: true });
    window.location.reload();
  };

  return (
    <>
      <Navbar />
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<BlogDetail />} />
        <Route path="/threatseye" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/request-demo" element={<RequestDemo />} />
        <Route path="/privacy-policy" element={<PricvacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsAndConditions />} />
        <Route path="/about" element={<About />} />
        <Route path="/getquote" element={<GetQuote />} />
        <Route exact path="/order/:plan" element={<Order />} />
        
        <Route path="/solutions" element={<Solutions />} />
        <Route
          path="/live-cyber-attacks"
          element={<LiveCyberAttacks onClick={handleLiveCyberAttacksClick} />}
        />
        <Route path="/dark-web-monitoring" element={<DarkWeb />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/data-breach-alerting" element={<DataBreach />} />
        <Route path="/spam-domain-tracking" element={<DomainMonitoring />} />
        <Route path="/cyber-threats-map" element={<ThreatsMap />} />
        <Route path="*" element={<E404 />} />
      </Routes>

      <GoToTop />
      <Footer />
    </>
  );
};

export default App;
