import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../../assets/img/Logo/logo-dark-full.png'
export default function MobileLogo() {
  return (
    <div className="mobile-nav">
        <div className="container">
            <Link to="/" className="logo">
                <img src={Logo} alt="Logo" />
            </Link>
        </div>
    </div>
  )
}
