import "./styles.css";

import React, { useEffect, useState } from "react";
import { DataMapsWrapper } from "react-typescript-datamaps";
import { cyberThreats } from "./worlds";
import { data } from "./AttacksList/giveJSON";

import getCountryFromIp from "../utils/getCountryFromIp";
import getCoordinatesFromIp from "../utils/getCoordinatesFromIp";
import ThreatsMapHeader from "../components/ThreatsMap/ThreatsMapHeader";
import CardHeader from "../components/ThreatsMap/CardHeader";
import CountryCard from "../components/ThreatsMap/CountryCard";

const windowMatchMedia = window.matchMedia("(max-width: 600px)");

const mapStyle = windowMatchMedia.matches
  ? { width: "90vw", height: "80vh" }
  : { width: "100%", height: "100%" };


function AttackHelper(srcloc, dstloc) {
  const tempIndex3 = Math.floor(Math.random() * 3);
  const threat = cyberThreats[tempIndex3];

  const origin = {
    latitude: srcloc.latitude,
    longitude: srcloc.longitude,
    name: srcloc.country,
  };
  const destination = {
    latitude: dstloc.latitude,
    longitude: dstloc.longitude,
    name: dstloc.country,
  };
  return {
    origin,
    destination,
    type: threat.name,
    color: threat.color,
    options: {
      destinationCircle: {
        r: 3.5,
        fillColor: threat.color,
        strokeColor: threat.color,
      },
      arcStrokeColor: threat.color,
      highlighted: dstloc.country, // add highlighted property
      scale: 400, // set zoom scale
    },
  };
}

function BlastHelper(loc) {
  const tempIndex2 = Math.floor(Math.random() * 3);
  const threat = cyberThreats[tempIndex2];

  return {
    latitude: loc.latitude,
    longitude: loc.longitude,
    fillKey: threat.name === "Ransomware" ? "MAJOR" : "MEDIUM",
    radius: 5,
    borderColor: threat.color,
    country: loc.country,
    animate: true,
    fillColor: "#FC8D59",
    borderWidth: 10,
    borderOpacity: 0.45,
    fillOpacity: 0.98,
    highlighted: loc.country, // add highlighted property
  };
}

function WorldMap() {
  const [index, setIndex] = useState(0);
  const [dst, setDst] = useState([]);
  const [src, setSrc] = useState([]);
  const [dstloc, setDstloc] = useState();
  const [srcloc, setSrcloc] = useState();
  const [IPv4, setIPv4] = useState();
  const [arc, setArc] = useState([]);
  const [blast, setBlast] = useState([]);

  const bubblesConfig = {
    popupOnHover: true,
    radius: null,
    borderWidth: 5,
    borderOpacity: 1,
    borderColor: "#FFFFFF",
    borderGlow: true,
    borderGlowColor: "blue",
    borderGlowSize: 30,
    popupTemplate: function (geo, data) {
      console.log(data);
      return `<div class="hoverinfo">${data.country}</div>`;
    },
    fillOpacity: 0.75,
    animate: true,
    highlightOnHover: true,
    highlightFillColor: "#FC8D59",
    highlightBorderColor: "rgba(250, 15, 160, 0.2)",
    highlightBorderWidth: 2,
    highlightBorderOpacity: 1,
    highlightFillOpacity: 0.85,
    exitDelay: 10000,
    key: JSON.stringify,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setArc([]);
      setBlast([]);
      setDst(() => {
        return data[0][index].Event.Attributes.filter(
          (item) => item.type === "ip-dst"
        );
      });
      setSrc(() => {
        return data[0][index].Event.Attributes.filter(
          (item) => item.type === "ip-src"
        );
      });
      setIPv4(() => {
        return data[0][index].Event.Attributes.find(
          (item) => item.type === "ipv4"
        );
      });

      setDst((prev) => prev.map((item) => item.value));
      setSrc((prev) => prev.map((item) => item.value));

      const executeAttack = async () => {
        if (src.length > 0) {
          const country = await getCountryFromIp(src[0]);
          const loc = await getCoordinatesFromIp(src[0]);
          setSrcloc({ country, ...loc });
        }
        if (dst.length > 0) {
          const country = await getCountryFromIp(dst[0]);
          const loc = await getCoordinatesFromIp(dst[0]);
          setDstloc({ country, ...loc });
        }

        if (src.length > 0 && dst.length > 0 && srcloc && dstloc) {
          const attack = AttackHelper(srcloc, dstloc);
          setBlast([]);
          setArc([attack]);

          console.log({ attack });
        } else if (src.length > 0 && srcloc) {
          const blast = BlastHelper(srcloc);
          setBlast([blast]);
        } else if (dst.length > 0 && dstloc) {
          const blast = BlastHelper(dstloc);
          setBlast([blast]);
          console.log({ blast });
        } else {
          if (IPv4) {
            const country = await getCountryFromIp(IPv4.value);
            const loc = await getCoordinatesFromIp(IPv4.value);
            setSrcloc({ country, ...loc });
            if (srcloc) {
              const blast = BlastHelper(srcloc);
              setBlast([blast]);
            }
          }
        }
      };
      executeAttack();
      if (index === 29) {
        setIndex(0);
      } else {
        setIndex((prev) => prev + 1);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [index, src, dst, srcloc, dstloc, IPv4]);

  return (
    <div>
      <DataMapsWrapper
        responsive
        attacks={arc}
        bubbles={blast}
        geographyConfig={{
          dataType: "topojson",
          dataUrl: "/map/map.topojson",
          popupOnHover: true,
          highlightOnHover: true,
          highlightFillColor: "#364d53",
          highlightBorderColor: "#364d53",
          borderColor: "#364d53",
          borderWidth: 0.5,
        }}
        fills={{
          defaultFill: "#101518",
          MAJOR: "#306596",
          MEDIUM: "#0fa0fa",
          MINOR: "#bada55",
        }}
        bubblesConfig={bubblesConfig}
      />
    </div>
  );
}

const ThreatsMap = () => {
  const [filterByDate, setFilterByDate] = useState("last_24_hours");

  return (
    <>
    <section className="pt-100" style={{ background: "black" }}>
    <div
      className="page-title-content text-center text-white"
      style={{ marginTop: "20px" }}></div>
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-lg-3"
          style={{ background: "black", minHeight: "90vh" }}>
          <div className="mr-4 mt-100 m-lg-5">
            <ThreatsMapHeader totalAttacks={453635} text={filterByDate} />
            <div className="text-xs mt-6">
              <CardHeader title="TOP 3 ATTACK ORIGINS" icon={""} />
              <div>
                <CountryCard
                  country_code="us"
                  country_name="United States"
                />
                <CountryCard country_code="ru" country_name="Russia" />
                <CountryCard country_code="cn" country_name="China" />
              </div>
            </div>
            <div className="text-xs">
              <CardHeader title="TOP 3 ATTACK TARGETS" icon={""} />
              <div>
                <CountryCard
                  country_code="sa"
                  country_name="Saudi Arabia"
                />
                <CountryCard country_code="ir" country_name="Iran" />
                <CountryCard country_code="vn" country_name="Vietnam" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="p-4" style={{ height: "80vh" }}>
            <div style={mapStyle}>
              <WorldMap />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  );
};

export default ThreatsMap;
