import React from 'react'
import { Link } from "react-router-dom";

export default function SocialIconItems({to, ...props }) {
  return (
    <li>
        <Link to={to}>
            <i className={props.icon}></i>
        </Link>
    </li>
  )
}
