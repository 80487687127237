import axios from "axios";

const getCountryFromIp = async (ip) => {
    // Extract the first octet of the IP address
    const octet = parseInt(ip.split(".")[0]);
  
    // Load the JSON data from the appropriate file using Axios
    const response = await axios.get(`${process.env.PUBLIC_URL}/map/countries/ip_from_${octet}.json`);
    const data = response.data;
  
    // Find the country with the given IP address
    const countryObj = data.find((c) => {
      const start = c.ip_from
        .split(".")
        .reduce((acc, octet) => acc * 256 + parseInt(octet), 0);
      const end = c.ip_to
        .split(".")
        .reduce((acc, octet) => acc * 256 + parseInt(octet), 0);
      const target = ip
        .split(".")
        .reduce((acc, octet) => acc * 256 + parseInt(octet), 0);
      return start <= target && target <= end;
    });
  
    // If match is found, return the country name
    return countryObj ? countryObj : null;
  }

export default getCountryFromIp