import React from 'react'
import SolutionImg from '../../assets/img/Solutions/solution-img.png'
import Solution from '../Cards/Solution'

const Solutions = () => {
  return (
    <section className="solution-area pb-70">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-6">
                    <div className="solution-content">
                    
                        <div className="solution-title">
                            <span>All-in Solution</span>
                            <h2>Power your cybersecurity intelligence solution with oursolution, covering millions of deep, dark and open web sites, forums, marketplaces, paste sites and more.</h2>
                        </div>

                        <div className="row">
                            <Solution
                                title="Threat Intelligence"
                                to={() => (false)}
                                description="The modern threat landscape is vast, complex, and constantly evolving. The idea that organizations can be fully secured against any and all potential threats has become untenable. ThreatsEye threat intelligence provides a window into the world of your adversary so you can identify, prioritize, and monitor the relevant threats to your organization."
                                numerotation="01"
                            />
                            <Solution
                                title="Live Threat Map"
                                to={() => (false)}
                                description="ThreatsEye Live Threat Map presents its new interactive Cyber threats Realtime Map. this visual tool allows users to see what is going on in cybersecurity around the world"
                                numerotation="02"
                            />
                            <Solution
                                title="Alerting"
                                to={() => (false)}
                                description="Data breaches are a growing concern for companies as hackers target them for information. But how can you know when your company has been hacked? With our data breach alerts, you receive alerts anytime there's a major breach. You will know when your company has been targeted and can take action to resolve the issue."
                                numerotation="03"
                            />
                           
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 pr-0">
                    <div className="solution-img" style={{ backgroundImage: `url(${SolutionImg})` }}>
                        <img src={SolutionImg} alt="Solutions" loading='lazy'/>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Solutions