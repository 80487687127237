import React from "react";
import { Link } from "react-router-dom";

export default function Copyright() {
  return (
    <p>
      Copyright <i className="bx bx-copyright"></i>{new Date().getFullYear()} {" "}
      <Link to="/"> {process.env.REACT_APP_NAME}</Link>.
    </p>
  );
}
