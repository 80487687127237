import React from "react";
import AboutImg from "../assets/img/About/about-img.jpg";
import PageTitleContent from "../components/Cards/PageTitleContent";

const About = () => {
  return (
    <>
      <PageTitleContent headline="About Us" title="About Us" />

      <section className="about-us-area pt-100 pb-70">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-img">
                <img src={AboutImg} alt="About" />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-content">
                <div className="about-title">
                  <span>About Us</span>
                  <h2>
                    Without stopping for a moment we give you best technology
                    experience discussing from our expertise to stop threats
                    being theft or damaged.
                  </h2>
                </div>

                <div className="tab">
                  <ul className="tabs">
                    <li>Why ThreatsEye?</li>
                  </ul>

                  <div className="tab_content">
                    <div className="tabs_item">
                      <p> Experience the latest in cyberattacks as they happen.</p>
                      
                      <p>No organization or country is immune to cyberattacks. That’s why ThreatsEye team is on high alert around the clock by monitoring, analyzing and understanding all the data in real-time.</p>
                      
                      <p>This way, we can better detect and act to thwart any eventuality, keeping information secure at all times.⁣⁣</p>
                      
                    
                      <ul>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          Trusted Partner
                        </li>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          Product Security
                        </li>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          System Security
                        </li>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          Operational Security
                        </li>
                      </ul>
                    </div>

                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
