import React from 'react'

export default function SingleCounter(props) {
  return (
    <div className={props.cols}>
      <div class="single-counter overly-one">
          <div class="overly-two">
              <i class={props.icon}></i>
              <h2>
                  <span class="odometer">{props.number}</span>
                  <span class="target">{props.target}</span>
              </h2>
              <h3>{props.title}</h3>
          </div>
      </div>
    </div>
  )
}
