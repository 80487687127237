import React, { useState, useEffect } from "react";
import DotMenu from "./NavbarMenuItems/DotMenu";
import MobileLogo from "./NavbarMenuItems/MobileLogo";
import NavbarBrand from "./NavbarMenuItems/NavbarBrand";
import RequestDemo from "./NavbarMenuItems/RequestDemo";
import RequestDemoMobile from "./NavbarMenuItems/RequestDemoMobile";
import NavbarContainer from "./NavbarMenuItems/NavbarContainer";
import MainNavContainer from "./NavbarMenuItems/MainNavContainer";
import NavbarListContainer from "./NavbarMenuItems/NavbarListContainer";
import NavbarListItems from "./NavbarMenuItems/NavbarListItems";
import DropdownMenu from "./NavbarMenuItems/DropdownMenu";
import MobileNavbar from "./NavbarMenuItems/MobileNavbar";
import MenuImg from "../../assets/img/menu.png";

const Navbar = () => {
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector(".navbar-area");
    const scrollTop = window.scrollY;
    scrollTop > 150
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  const [isActive, setActive] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isClose, setClose] = useState(false);

  const handleToggle = () => {
    setActive(!isActive);
  };

  const handleOpen = () => {
    setOpen(!isOpen);
    setClose(true);
  };

  const handleClose = () => {
    setClose(!isClose);
    setOpen(false);
  };
  return (
    <header className="header-area">
     
      <div className="navbar-area navbar-area-four">
        <MobileLogo />

        <MainNavContainer>
          <NavbarBrand />
          <NavbarContainer>
            <NavbarListContainer>
              <NavbarListItems to="/" text="Home" />
              <NavbarListItems to="/blog" text="Blog" />
              <NavbarListItems to="/about" text="About" />

              <li className="nav-item">
                <a
                  href={() => false}
                 
                  className="nav-link"
                >
                  Solutions <i className="bx bx-chevron-down"></i>
                </a>
                <DropdownMenu>
                  <NavbarListItems
                    to="/data-breach-alerting"
                    text="Data Breach Alerting"
                  />
                  <NavbarListItems
                    to="/dark-web-monitoring"
                    text="Dark Web Monitoring"
                  />
                  <NavbarListItems
                    to="/spam-domain-tracking"
                    text="Spam Domain Tracking"
                  />
                  <NavbarListItems
                    to="/live-cyber-attacks"
                    text="Live Cyber Attacks"
                  />
                </DropdownMenu>
              </li>
              <NavbarListItems to="/cyber-threats-map" text="Cyber Attacks Map" />
              <NavbarListItems to="/getquote" text="Get a Quote" />
              <NavbarListItems to="/contact" text="Contact" />
            </NavbarListContainer>

            <RequestDemo />
          </NavbarContainer>
        </MainNavContainer>

        <div className="others-option-for-responsive">
          <div className="container">
            <div className="dot-menu" onClick={handleToggle}>
              <DotMenu />
            </div>

            {!isClose && (
              <div className="navigation">
                <img src={MenuImg} alt="menu" onClick={handleOpen} />
              </div>
            )}

            <div className={isActive ? "container active" : "container"}>
              <RequestDemoMobile />
            </div>

            {isOpen && (
              <div className="mobile-nav mean-container">
                <div className="mean-bar">
                  <a
                    href={() => false}
                    onClick={handleClose}
                    className="meanmenu-reveal "
                    style={{
                      right: "0px",
                      left: "auto",
                      textAlign: "center",
                      textIndent: "0px",
                      fontSize: "18px",
                    }}
                  >
                    X
                  </a>

                  <MobileNavbar />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
