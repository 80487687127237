export const cyberThreats = [
    {
        name: 'Ransomware',
        color: 'red',
    },
    {
        name: 'Malware',
        color: 'orange',
    },
    {
        name: 'Phishing',
        color: 'yellow',
    },
    {
        name: 'DDoS',
        color: 'pink',
    }
];
export const world = [
        {
            "city": "Tokyo",
            "latitude": "35.6897",
            "longitude": "139.6922",
            "country": "Japan",
            "iso2": "JP",
            "iso3": "JPN",
            "capital": "primary",
            "population": "37977000",
            "id": "1392685764"
        },
        {
            "city": "Jakarta",
            "latitude": "-6.2146",
            "longitude": "106.8451",
            "country": "Indonesia",
            "iso2": "ID",
            "iso3": "IDN",
            "capital": "primary",
            "population": "34540000",
            "id": "1360771077"
        },
        {
            "city": "Manila",
            "latitude": "14.5958",
            "longitude": "120.9772",
            "country": "Philippines",
            "iso2": "PH",
            "iso3": "PHL",
            "capital": "primary",
            "population": "23088000",
            "id": "1608618140"
        },
        {
            "city": "Seoul",
            "latitude": "37.5833",
            "longitude": "127",
            "country": "Korea, South",
            "iso2": "KR",
            "iso3": "KOR",
            "capital": "primary",
            "population": "21794000",
            "id": "1410836482"
        },
        {
            "city": "Mexico City",
            "latitude": "19.4333",
            "longitude": "-99.1333",
            "country": "Mexico",
            "iso2": "MX",
            "iso3": "MEX",
            "capital": "primary",
            "population": "20996000",
            "id": "1484247881"
        },
        {
            "city": "Beijing",
            "latitude": "39.905",
            "longitude": "116.3914",
            "country": "China",
            "iso2": "CN",
            "iso3": "CHN",
            "capital": "primary",
            "population": "19433000",
            "id": "1156228865"
        },
        {
            "city": "Cairo",
            "latitude": "30.0561",
            "longitude": "31.2394",
            "country": "Egypt",
            "iso2": "EG",
            "iso3": "EGY",
            "capital": "primary",
            "population": "19372000",
            "id": "1818253931"
        },
        {
            "city": "Moscow",
            "latitude": "55.7558",
            "longitude": "37.6178",
            "country": "Russia",
            "iso2": "RU",
            "iso3": "RUS",
            "capital": "primary",
            "population": "17125000",
            "id": "1643318494"
        },
        {
            "city": "Bangkok",
            "latitude": "13.75",
            "longitude": "100.5167",
            "country": "Thailand",
            "iso2": "TH",
            "iso3": "THA",
            "capital": "primary",
            "population": "17066000",
            "id": "1764068610"
        },
        {
            "city": "Buenos Aires",
            "latitude": "-34.5997",
            "longitude": "-58.3819",
            "country": "Argentina",
            "iso2": "AR",
            "iso3": "ARG",
            "capital": "primary",
            "population": "16157000",
            "id": "1032717330"
        },
        {
            "city": "Dhaka",
            "latitude": "23.7161",
            "longitude": "90.3961",
            "country": "Bangladesh",
            "iso2": "BD",
            "iso3": "BGD",
            "capital": "primary",
            "population": "15443000",
            "id": "1050529279"
        },
        {
            "city": "Tehran",
            "latitude": "35.7",
            "longitude": "51.4167",
            "country": "Iran",
            "iso2": "IR",
            "iso3": "IRN",
            "capital": "primary",
            "population": "13633000",
            "id": "1364305026"
        },
        {
            "city": "Kinshasa",
            "latitude": "-4.3317",
            "longitude": "15.3139",
            "country": "Congo (Kinshasa)",
            "iso2": "CD",
            "iso3": "COD",
            "capital": "primary",
            "population": "13528000",
            "id": "1180000363"
        },
        {
            "city": "Paris",
            "latitude": "48.8566",
            "longitude": "2.3522",
            "country": "France",
            "iso2": "FR",
            "iso3": "FRA",
            "capital": "primary",
            "population": "11020000",
            "id": "1250015082"
        },
        {
            "city": "London",
            "latitude": "51.5072",
            "longitude": "-0.1275",
            "country": "United Kingdom",
            "iso2": "GB",
            "iso3": "GBR",
            "capital": "primary",
            "population": "10979000",
            "id": "1826645935"
        },
        {
            "city": "Lima",
            "latitude": "-12.05",
            "longitude": "-77.0333",
            "country": "Peru",
            "iso2": "PE",
            "iso3": "PER",
            "capital": "primary",
            "population": "9848000",
            "id": "1604728603"
        },
        {
            "city": "Bogotá",
            "latitude": "4.6126",
            "longitude": "-74.0705",
            "country": "Colombia",
            "iso2": "CO",
            "iso3": "COL",
            "capital": "primary",
            "population": "9464000",
            "id": "1170483426"
        },
        {
            "city": "Luanda",
            "latitude": "-8.8383",
            "longitude": "13.2344",
            "country": "Angola",
            "iso2": "AO",
            "iso3": "AGO",
            "capital": "primary",
            "population": "8417000",
            "id": "1024949724"
        },
        {
            "city": "Kuala Lumpur",
            "latitude": "3.1478",
            "longitude": "101.6953",
            "country": "Malaysia",
            "iso2": "MY",
            "iso3": "MYS",
            "capital": "primary",
            "population": "8285000",
            "id": "1458988644"
        },
        {
            "city": "Hanoi",
            "latitude": "21.0245",
            "longitude": "105.8412",
            "country": "Vietnam",
            "iso2": "VN",
            "iso3": "VNM",
            "capital": "primary",
            "population": "7785000",
            "id": "1704413791"
        },
        {
            "city": "Khartoum",
            "latitude": "15.6031",
            "longitude": "32.5265",
            "country": "Sudan",
            "iso2": "SD",
            "iso3": "SDN",
            "capital": "primary",
            "population": "7282000",
            "id": "1729268475"
        },
        {
            "city": "Santiago",
            "latitude": "-33.45",
            "longitude": "-70.6667",
            "country": "Chile",
            "iso2": "CL",
            "iso3": "CHL",
            "capital": "primary",
            "population": "7007000",
            "id": "1152554349"
        },
        {
            "city": "Riyadh",
            "latitude": "24.65",
            "longitude": "46.71",
            "country": "Saudi Arabia",
            "iso2": "SA",
            "iso3": "SAU",
            "capital": "primary",
            "population": "6881000",
            "id": "1682999334"
        },
        {
            "city": "Dar es Salaam",
            "latitude": "-6.8",
            "longitude": "39.2833",
            "country": "Tanzania",
            "iso2": "TZ",
            "iso3": "TZA",
            "capital": "primary",
            "population": "6698000",
            "id": "1834843853"
        },
        {
            "city": "Baghdad",
            "latitude": "33.35",
            "longitude": "44.4167",
            "country": "Iraq",
            "iso2": "IQ",
            "iso3": "IRQ",
            "capital": "primary",
            "population": "5796000",
            "id": "1368596238"
        },
        {
            "city": "Singapore",
            "latitude": "1.3",
            "longitude": "103.8",
            "country": "Singapore",
            "iso2": "SG",
            "iso3": "SGP",
            "capital": "primary",
            "population": "5745000",
            "id": "1702341327"
        },
        {
            "city": "Nairobi",
            "latitude": "-1.2864",
            "longitude": "36.8172",
            "country": "Kenya",
            "iso2": "KE",
            "iso3": "KEN",
            "capital": "primary",
            "population": "5545000",
            "id": "1404000661"
        },
        {
            "city": "Ankara",
            "latitude": "39.93",
            "longitude": "32.85",
            "country": "Turkey",
            "iso2": "TR",
            "iso3": "TUR",
            "capital": "primary",
            "population": "5503985",
            "id": "1792572891"
        },
        {
            "city": "Rangoon",
            "latitude": "16.8",
            "longitude": "96.15",
            "country": "Burma",
            "iso2": "MM",
            "iso3": "MMR",
            "capital": "primary",
            "population": "5430000",
            "id": "1104616656"
        },
        {
            "city": "Washington",
            "latitude": "38.9047",
            "longitude": "-77.0163",
            "country": "United States",
            "iso2": "US",
            "iso3": "USA",
            "capital": "primary",
            "population": "5379184",
            "id": "1840006060"
        },
        {
            "city": "Abidjan",
            "latitude": "5.3364",
            "longitude": "-4.0267",
            "country": "Côte D’Ivoire",
            "iso2": "CI",
            "iso3": "CIV",
            "capital": "primary",
            "population": "4980000",
            "id": "1384207980"
        },
        {
            "city": "Amman",
            "latitude": "31.95",
            "longitude": "35.9333",
            "country": "Jordan",
            "iso2": "JO",
            "iso3": "JOR",
            "capital": "primary",
            "population": "4007526",
            "id": "1400522593"
        },
        {
            "city": "Kabul",
            "latitude": "34.5328",
            "longitude": "69.1658",
            "country": "Afghanistan",
            "iso2": "AF",
            "iso3": "AFG",
            "capital": "primary",
            "population": "3678034",
            "id": "1004993580"
        },
        {
            "city": "Berlin",
            "latitude": "52.5167",
            "longitude": "13.3833",
            "country": "Germany",
            "iso2": "DE",
            "iso3": "DEU",
            "capital": "primary",
            "population": "3644826",
            "id": "1276451290"
        },
        {
            "city": "Algiers",
            "latitude": "36.7764",
            "longitude": "3.0586",
            "country": "Algeria",
            "iso2": "DZ",
            "iso3": "DZA",
            "capital": "primary",
            "population": "3415811",
            "id": "1012973369"
        },
        {
            "city": "Madrid",
            "latitude": "40.4189",
            "longitude": "-3.6919",
            "country": "Spain",
            "iso2": "ES",
            "iso3": "ESP",
            "capital": "primary",
            "population": "3266126",
            "id": "1724616994"
        },
        {
            "city": "Addis Ababa",
            "latitude": "9.0272",
            "longitude": "38.7369",
            "country": "Ethiopia",
            "iso2": "ET",
            "iso3": "ETH",
            "capital": "primary",
            "population": "3041002",
            "id": "1231824991"
        },
        {
            "city": "Brasília",
            "latitude": "-15.7744",
            "longitude": "-48.0773",
            "country": "Brazil",
            "iso2": "BR",
            "iso3": "BRA",
            "capital": "primary",
            "population": "3015268",
            "id": "1076144436"
        },
        {
            "city": "Kyiv",
            "latitude": "50.45",
            "longitude": "30.5236",
            "country": "Ukraine",
            "iso2": "UA",
            "iso3": "UKR",
            "capital": "primary",
            "population": "2967000",
            "id": "1804382913"
        },
        {
            "city": "Sanaa",
            "latitude": "15.35",
            "longitude": "44.2",
            "country": "Yemen",
            "iso2": "YE",
            "iso3": "YEM",
            "capital": "primary",
            "population": "2957000",
            "id": "1887750814"
        },
        {
            "city": "Rome",
            "latitude": "41.8931",
            "longitude": "12.4828",
            "country": "Italy",
            "iso2": "IT",
            "iso3": "ITA",
            "capital": "primary",
            "population": "2872800",
            "id": "1380382862"
        },
        {
            "city": "La Paz",
            "latitude": "-16.4942",
            "longitude": "-68.1475",
            "country": "Bolivia",
            "iso2": "BO",
            "iso3": "BOL",
            "capital": "primary",
            "population": "2867504",
            "id": "1068000064"
        },
        {
            "city": "Pyongyang",
            "latitude": "39.03",
            "longitude": "125.73",
            "country": "Korea, North",
            "iso2": "KP",
            "iso3": "PRK",
            "capital": "primary",
            "population": "2863000",
            "id": "1408738594"
        },
        {
            "city": "Taipei",
            "latitude": "25.0478",
            "longitude": "121.5319",
            "country": "Taiwan",
            "iso2": "TW",
            "iso3": "TWN",
            "capital": "primary",
            "population": "2684567",
            "id": "1158881289"
        },
        {
            "city": "Antananarivo",
            "latitude": "-18.9386",
            "longitude": "47.5214",
            "country": "Madagascar",
            "iso2": "MG",
            "iso3": "MDG",
            "capital": "primary",
            "population": "2610000",
            "id": "1450978461"
        },
        {
            "city": "Santo Domingo",
            "latitude": "18.4764",
            "longitude": "-69.8933",
            "country": "Dominican Republic",
            "iso2": "DO",
            "iso3": "DOM",
            "capital": "primary",
            "population": "2581827",
            "id": "1214636202"
        },
        {
            "city": "Guatemala City",
            "latitude": "14.6099",
            "longitude": "-90.5252",
            "country": "Guatemala",
            "iso2": "GT",
            "iso3": "GTM",
            "capital": "primary",
            "population": "2450212",
            "id": "1320197916"
        },
        {
            "city": "Yaoundé",
            "latitude": "3.8578",
            "longitude": "11.5181",
            "country": "Cameroon",
            "iso2": "CM",
            "iso3": "CMR",
            "capital": "primary",
            "population": "2440462",
            "id": "1120298240"
        },
        {
            "city": "Tashkent",
            "latitude": "41.3",
            "longitude": "69.2667",
            "country": "Uzbekistan",
            "iso2": "UZ",
            "iso3": "UZB",
            "capital": "primary",
            "population": "2424100",
            "id": "1860331871"
        },
        {
            "city": "Accra",
            "latitude": "5.6037",
            "longitude": "-0.187",
            "country": "Ghana",
            "iso2": "GH",
            "iso3": "GHA",
            "capital": "primary",
            "population": "2291352",
            "id": "1288299415"
        },
        {
            "city": "Baku",
            "latitude": "40.3667",
            "longitude": "49.8352",
            "country": "Azerbaijan",
            "iso2": "AZ",
            "iso3": "AZE",
            "capital": "primary",
            "population": "2181800",
            "id": "1031946365"
        },
        {
            "city": "Havana",
            "latitude": "23.1367",
            "longitude": "-82.3589",
            "country": "Cuba",
            "iso2": "CU",
            "iso3": "CUB",
            "capital": "primary",
            "population": "2141652",
            "id": "1192752771"
        },
        {
            "city": "Phnom Penh",
            "latitude": "11.5696",
            "longitude": "104.921",
            "country": "Cambodia",
            "iso2": "KH",
            "iso3": "KHM",
            "capital": "primary",
            "population": "2129371",
            "id": "1116260534"
        },
        {
            "city": "Mogadishu",
            "latitude": "2.0408",
            "longitude": "45.3425",
            "country": "Somalia",
            "iso2": "SO",
            "iso3": "SOM",
            "capital": "primary",
            "population": "2120000",
            "id": "1706893395"
        },
        {
            "city": "Minsk",
            "latitude": "53.9022",
            "longitude": "27.5618",
            "country": "Belarus",
            "iso2": "BY",
            "iso3": "BLR",
            "capital": "primary",
            "population": "2020600",
            "id": "1112348503"
        },
        {
            "city": "Bamako",
            "latitude": "12.6458",
            "longitude": "-7.9922",
            "country": "Mali",
            "iso2": "ML",
            "iso3": "MLI",
            "capital": "primary",
            "population": "2009109",
            "id": "1466965925"
        },
        {
            "city": "Quito",
            "latitude": "-0.2186",
            "longitude": "-78.5097",
            "country": "Ecuador",
            "iso2": "EC",
            "iso3": "ECU",
            "capital": "primary",
            "population": "2011388",
            "id": "1218441993"
        },
        {
            "city": "Caracas",
            "latitude": "10.5",
            "longitude": "-66.9333",
            "country": "Venezuela",
            "iso2": "VE",
            "iso3": "VEN",
            "capital": "primary",
            "population": "1943901",
            "id": "1862748204"
        },
        {
            "city": "Bucharest",
            "latitude": "44.4",
            "longitude": "26.0833",
            "country": "Romania",
            "iso2": "RO",
            "iso3": "ROU",
            "capital": "primary",
            "population": "1883425",
            "id": "1642414442"
        },
        {
            "city": "Asunción",
            "latitude": "-25.3",
            "longitude": "-57.6333",
            "country": "Paraguay",
            "iso2": "PY",
            "iso3": "PRY",
            "capital": "primary",
            "population": "1870000",
            "id": "1600057911"
        },
        {
            "city": "Vienna",
            "latitude": "48.2083",
            "longitude": "16.3731",
            "country": "Austria",
            "iso2": "AT",
            "iso3": "AUT",
            "capital": "primary",
            "population": "1840573",
            "id": "1040261752"
        },
        {
            "city": "Brazzaville",
            "latitude": "-4.2667",
            "longitude": "15.2833",
            "country": "Congo (Brazzaville)",
            "iso2": "CG",
            "iso3": "COG",
            "capital": "primary",
            "population": "1827000",
            "id": "1178340306"
        },
        {
            "city": "Warsaw",
            "latitude": "52.2167",
            "longitude": "21.0333",
            "country": "Poland",
            "iso2": "PL",
            "iso3": "POL",
            "capital": "primary",
            "population": "1790658",
            "id": "1616024847"
        },
        {
            "city": "Damascus",
            "latitude": "33.5131",
            "longitude": "36.2919",
            "country": "Syria",
            "iso2": "SY",
            "iso3": "SYR",
            "capital": "primary",
            "population": "1754000",
            "id": "1760685964"
        },
        {
            "city": "Budapest",
            "latitude": "47.4983",
            "longitude": "19.0408",
            "country": "Hungary",
            "iso2": "HU",
            "iso3": "HUN",
            "capital": "primary",
            "population": "1752286",
            "id": "1348611435"
        },
        {
            "city": "Lusaka",
            "latitude": "-15.4167",
            "longitude": "28.2833",
            "country": "Zambia",
            "iso2": "ZM",
            "iso3": "ZMB",
            "capital": "primary",
            "population": "1742979",
            "id": "1894157390"
        },
        {
            "city": "Conakry",
            "latitude": "9.5092",
            "longitude": "-13.7122",
            "country": "Guinea",
            "iso2": "GN",
            "iso3": "GIN",
            "capital": "primary",
            "population": "1667864",
            "id": "1324568159"
        },
        {
            "city": "Kampala",
            "latitude": "0.3136",
            "longitude": "32.5811",
            "country": "Uganda",
            "iso2": "UG",
            "iso3": "UGA",
            "capital": "primary",
            "population": "1659600",
            "id": "1800406299"
        },
        {
            "city": "Rabat",
            "latitude": "34.0253",
            "longitude": "-6.8361",
            "country": "Morocco",
            "iso2": "MA",
            "iso3": "MAR",
            "capital": "primary",
            "population": "1628000",
            "id": "1504023252"
        },
        {
            "city": "Ouagadougou",
            "latitude": "12.3572",
            "longitude": "-1.5353",
            "country": "Burkina Faso",
            "iso2": "BF",
            "iso3": "BFA",
            "capital": "primary",
            "population": "1626950",
            "id": "1854029208"
        },
        {
            "city": "Harare",
            "latitude": "-17.8292",
            "longitude": "31.0522",
            "country": "Zimbabwe",
            "iso2": "ZW",
            "iso3": "ZWE",
            "capital": "primary",
            "population": "1606000",
            "id": "1716196799"
        },
        {
            "city": "Muscat",
            "latitude": "23.6139",
            "longitude": "58.5922",
            "country": "Oman",
            "iso2": "OM",
            "iso3": "OMN",
            "capital": "primary",
            "population": "1421409",
            "id": "1512035506"
        },
        {
            "city": "The Hague",
            "latitude": "52.08",
            "longitude": "4.27",
            "country": "Netherlands",
            "iso2": "NL",
            "iso3": "NLD",
            "capital": "primary",
            "population": "1406000",
            "id": "1528799905"
        },
        {
            "city": "Ulaanbaatar",
            "latitude": "47.9203",
            "longitude": "106.9172",
            "country": "Mongolia",
            "iso2": "MN",
            "iso3": "MNG",
            "capital": "primary",
            "population": "1396288",
            "id": "1496024767"
        },
        {
            "city": "Belgrade",
            "latitude": "44.8167",
            "longitude": "20.4667",
            "country": "Serbia",
            "iso2": "RS",
            "iso3": "SRB",
            "capital": "primary",
            "population": "1378682",
            "id": "1688374696"
        },
        {
            "city": "Islamabad",
            "latitude": "33.6989",
            "longitude": "73.0369",
            "country": "Pakistan",
            "iso2": "PK",
            "iso3": "PAK",
            "capital": "primary",
            "population": "1365000",
            "id": "1586306717"
        },
        {
            "city": "Sofia",
            "latitude": "42.6975",
            "longitude": "23.3241",
            "country": "Bulgaria",
            "iso2": "BG",
            "iso3": "BGR",
            "capital": "primary",
            "population": "1355142",
            "id": "1100762037"
        },
        {
            "city": "Prague",
            "latitude": "50.0833",
            "longitude": "14.4167",
            "country": "Czechia",
            "iso2": "CZ",
            "iso3": "CZE",
            "capital": "primary",
            "population": "1324277",
            "id": "1203744823"
        },
        {
            "city": "Montevideo",
            "latitude": "-34.8667",
            "longitude": "-56.1667",
            "country": "Uruguay",
            "iso2": "UY",
            "iso3": "URY",
            "capital": "primary",
            "population": "1319108",
            "id": "1858107000"
        },
        {
            "city": "Doha",
            "latitude": "25.3",
            "longitude": "51.5333",
            "country": "Qatar",
            "iso2": "QA",
            "iso3": "QAT",
            "capital": "primary",
            "population": "1312947",
            "id": "1634459660"
        },
        {
            "city": "Abuja",
            "latitude": "9.0556",
            "longitude": "7.4914",
            "country": "Nigeria",
            "iso2": "NG",
            "iso3": "NGA",
            "capital": "primary",
            "population": "1235880",
            "id": "1566342270"
        },
        {
            "city": "Tunis",
            "latitude": "36.8008",
            "longitude": "10.18",
            "country": "Tunisia",
            "iso2": "TN",
            "iso3": "TUN",
            "capital": "primary",
            "population": "1200000",
            "id": "1788742103"
        },
        {
            "city": "Maputo",
            "latitude": "-25.9153",
            "longitude": "32.5764",
            "country": "Mozambique",
            "iso2": "MZ",
            "iso3": "MOZ",
            "capital": "primary",
            "population": "1191613",
            "id": "1508074843"
        },
        {
            "city": "Dublin",
            "latitude": "53.3425",
            "longitude": "-6.2658",
            "country": "Ireland",
            "iso2": "IE",
            "iso3": "IRL",
            "capital": "primary",
            "population": "1173179",
            "id": "1372595407"
        },
        {
            "city": "Nay Pyi Taw",
            "latitude": "19.8028",
            "longitude": "96.1583",
            "country": "Burma",
            "iso2": "MM",
            "iso3": "MMR",
            "capital": "primary",
            "population": "1160242",
            "id": "1104838105"
        },
        {
            "city": "Dakar",
            "latitude": "14.7319",
            "longitude": "-17.4572",
            "country": "Senegal",
            "iso2": "SN",
            "iso3": "SEN",
            "capital": "primary",
            "population": "1146053",
            "id": "1686604760"
        },
        {
            "city": "Tegucigalpa",
            "latitude": "14.0942",
            "longitude": "-87.2067",
            "country": "Honduras",
            "iso2": "HN",
            "iso3": "HND",
            "capital": "primary",
            "population": "1126534",
            "id": "1340344059"
        },
        {
            "city": "Tripoli",
            "latitude": "32.8752",
            "longitude": "13.1875",
            "country": "Libya",
            "iso2": "LY",
            "iso3": "LBY",
            "capital": "primary",
            "population": "1126000",
            "id": "1434201852"
        },
        {
            "city": "Tbilisi",
            "latitude": "41.7225",
            "longitude": "44.7925",
            "country": "Georgia",
            "iso2": "GE",
            "iso3": "GEO",
            "capital": "primary",
            "population": "1118035",
            "id": "1268203191"
        },
        {
            "city": "N’Djamena",
            "latitude": "12.11",
            "longitude": "15.05",
            "country": "Chad",
            "iso2": "TD",
            "iso3": "TCD",
            "capital": "primary",
            "population": "1092066",
            "id": "1148708596"
        },
        {
            "city": "Copenhagen",
            "latitude": "55.6786",
            "longitude": "12.5635",
            "country": "Denmark",
            "iso2": "DK",
            "iso3": "DNK",
            "capital": "primary",
            "population": "1085000",
            "id": "1208763942"
        },
        {
            "city": "Yerevan",
            "latitude": "40.1814",
            "longitude": "44.5144",
            "country": "Armenia",
            "iso2": "AM",
            "iso3": "ARM",
            "capital": "primary",
            "population": "1075800",
            "id": "1051074169"
        },
        {
            "city": "Nur-Sultan",
            "latitude": "51.1333",
            "longitude": "71.4333",
            "country": "Kazakhstan",
            "iso2": "KZ",
            "iso3": "KAZ",
            "capital": "primary",
            "population": "1078362",
            "id": "1398516045"
        },
        {
            "city": "Nouakchott",
            "latitude": "18.0783",
            "longitude": "-15.9744",
            "country": "Mauritania",
            "iso2": "MR",
            "iso3": "MRT",
            "capital": "primary",
            "population": "1077169",
            "id": "1478414984"
        },
        {
            "city": "Bishkek",
            "latitude": "42.8667",
            "longitude": "74.5667",
            "country": "Kyrgyzstan",
            "iso2": "KG",
            "iso3": "KGZ",
            "capital": "primary",
            "population": "1027200",
            "id": "1417191971"
        },
        {
            "city": "Amsterdam",
            "latitude": "52.35",
            "longitude": "4.9166",
            "country": "Netherlands",
            "iso2": "NL",
            "iso3": "NLD",
            "capital": "primary",
            "population": "1031000",
            "id": "1528355309"
        },
        {
            "city": "Ashgabat",
            "latitude": "37.95",
            "longitude": "58.3833",
            "country": "Turkmenistan",
            "iso2": "TM",
            "iso3": "TKM",
            "capital": "primary",
            "population": "1031992",
            "id": "1795049992"
        },
        {
            "city": "Niamey",
            "latitude": "13.5086",
            "longitude": "2.1111",
            "country": "Niger",
            "iso2": "NE",
            "iso3": "NER",
            "capital": "primary",
            "population": "1026848",
            "id": "1562932886"
        },
        {
            "city": "Managua",
            "latitude": "12.15",
            "longitude": "-86.2667",
            "country": "Nicaragua",
            "iso2": "NI",
            "iso3": "NIC",
            "capital": "primary",
            "population": "1028808",
            "id": "1558296252"
        },
        {
            "city": "Monrovia",
            "latitude": "6.3106",
            "longitude": "-10.8047",
            "country": "Liberia",
            "iso2": "LR",
            "iso3": "LBR",
            "capital": "primary",
            "population": "1021762",
            "id": "1430477826"
        },
        {
            "city": "Port-au-Prince",
            "latitude": "18.5425",
            "longitude": "-72.3386",
            "country": "Haiti",
            "iso2": "HT",
            "iso3": "HTI",
            "capital": "primary",
            "population": "987310",
            "id": "1332401940"
        },
        {
            "city": "Kathmandu",
            "latitude": "27.7167",
            "longitude": "85.3667",
            "country": "Nepal",
            "iso2": "NP",
            "iso3": "NPL",
            "capital": "primary",
            "population": "975453",
            "id": "1524589448"
        },
        {
            "city": "Abu Dhabi",
            "latitude": "24.4781",
            "longitude": "54.3686",
            "country": "United Arab Emirates",
            "iso2": "AE",
            "iso3": "ARE",
            "capital": "primary",
            "population": "1000000",
            "id": "1784176710"
        },
        {
            "city": "Stockholm",
            "latitude": "59.3294",
            "longitude": "18.0686",
            "country": "Sweden",
            "iso2": "SE",
            "iso3": "SWE",
            "capital": "primary",
            "population": "972647",
            "id": "1752425602"
        },
        {
            "city": "Asmara",
            "latitude": "15.3333",
            "longitude": "38.9167",
            "country": "Eritrea",
            "iso2": "ER",
            "iso3": "ERI",
            "capital": "primary",
            "population": "963000",
            "id": "1232791236"
        },
        {
            "city": "Freetown",
            "latitude": "8.4833",
            "longitude": "-13.2331",
            "country": "Sierra Leone",
            "iso2": "SL",
            "iso3": "SLE",
            "capital": "primary",
            "population": "951000",
            "id": "1694085071"
        },
        {
            "city": "Jerusalem",
            "latitude": "31.7833",
            "longitude": "35.2167",
            "country": "Israel",
            "iso2": "IL",
            "iso3": "ISR",
            "capital": "primary",
            "population": "919438",
            "id": "1376261644"
        },
        {
            "city": "Panama City",
            "latitude": "9",
            "longitude": "-79.5",
            "country": "Panama",
            "iso2": "PA",
            "iso3": "PAN",
            "capital": "primary",
            "population": "880691",
            "id": "1591672475"
        },
        {
            "city": "Lomé",
            "latitude": "6.1319",
            "longitude": "1.2228",
            "country": "Togo",
            "iso2": "TG",
            "iso3": "TGO",
            "capital": "primary",
            "population": "837437",
            "id": "1768409132"
        },
        {
            "city": "Libreville",
            "latitude": "0.3901",
            "longitude": "9.4544",
            "country": "Gabon",
            "iso2": "GA",
            "iso3": "GAB",
            "capital": "primary",
            "population": "797003",
            "id": "1266952885"
        },
        {
            "city": "Zagreb",
            "latitude": "45.8",
            "longitude": "15.95",
            "country": "Croatia",
            "iso2": "HR",
            "iso3": "HRV",
            "capital": "primary",
            "population": "790017",
            "id": "1191233290"
        },
        {
            "city": "Dushanbe",
            "latitude": "38.5731",
            "longitude": "68.7864",
            "country": "Tajikistan",
            "iso2": "TJ",
            "iso3": "TJK",
            "capital": "primary",
            "population": "780000",
            "id": "1762930616"
        },
        {
            "city": "Lilongwe",
            "latitude": "-13.9833",
            "longitude": "33.7833",
            "country": "Malawi",
            "iso2": "MW",
            "iso3": "MWI",
            "capital": "primary",
            "population": "781538",
            "id": "1454688499"
        },
        {
            "city": "Cotonou",
            "latitude": "6.4",
            "longitude": "2.52",
            "country": "Benin",
            "iso2": "BJ",
            "iso3": "BEN",
            "capital": "primary",
            "population": "762000",
            "id": "1204955174"
        },
        {
            "city": "Vientiane",
            "latitude": "17.9667",
            "longitude": "102.6",
            "country": "Laos",
            "iso2": "LA",
            "iso3": "LAO",
            "capital": "primary",
            "population": "760000",
            "id": "1418732714"
        },
        {
            "city": "Colombo",
            "latitude": "6.9167",
            "longitude": "79.8333",
            "country": "Sri Lanka",
            "iso2": "LK",
            "iso3": "LKA",
            "capital": "primary",
            "population": "752993",
            "id": "1144251314"
        },
        {
            "city": "Kigali",
            "latitude": "-1.9536",
            "longitude": "30.0606",
            "country": "Rwanda",
            "iso2": "RW",
            "iso3": "RWA",
            "capital": "primary",
            "population": "745261",
            "id": "1646923541"
        },
        {
            "city": "Pretoria",
            "latitude": "-25.7464",
            "longitude": "28.1881",
            "country": "South Africa",
            "iso2": "ZA",
            "iso3": "ZAF",
            "capital": "primary",
            "population": "741651",
            "id": "1710176249"
        },
        {
            "city": "Bangui",
            "latitude": "4.3732",
            "longitude": "18.5628",
            "country": "Central African Republic",
            "iso2": "CF",
            "iso3": "CAF",
            "capital": "primary",
            "population": "734350",
            "id": "1140080881"
        },
        {
            "city": "Riga",
            "latitude": "56.9475",
            "longitude": "24.1069",
            "country": "Latvia",
            "iso2": "LV",
            "iso3": "LVA",
            "capital": "primary",
            "population": "698529",
            "id": "1428586192"
        },
        {
            "city": "Oslo",
            "latitude": "59.9111",
            "longitude": "10.7528",
            "country": "Norway",
            "iso2": "NO",
            "iso3": "NOR",
            "capital": "primary",
            "population": "693494",
            "id": "1578324706"
        },
        {
            "city": "Chisinau",
            "latitude": "47.0056",
            "longitude": "28.8575",
            "country": "Moldova",
            "iso2": "MD",
            "iso3": "MDA",
            "capital": "primary",
            "population": "685900",
            "id": "1498011437"
        },
        {
            "city": "Athens",
            "latitude": "37.9794",
            "longitude": "23.7161",
            "country": "Greece",
            "iso2": "GR",
            "iso3": "GRC",
            "capital": "primary",
            "population": "664046",
            "id": "1300715560"
        },
        {
            "city": "Bujumbura",
            "latitude": "-3.3825",
            "longitude": "29.3611",
            "country": "Burundi",
            "iso2": "BI",
            "iso3": "BDI",
            "capital": "primary",
            "population": "658859",
            "id": "1108101689"
        },
        {
            "city": "Helsinki",
            "latitude": "60.1756",
            "longitude": "24.9342",
            "country": "Finland",
            "iso2": "FI",
            "iso3": "FIN",
            "capital": "primary",
            "population": "642045",
            "id": "1246177997"
        },
        {
            "city": "Skopje",
            "latitude": "41.9833",
            "longitude": "21.4333",
            "country": "Macedonia",
            "iso2": "MK",
            "iso3": "MKD",
            "capital": "primary",
            "population": "640000",
            "id": "1807600615"
        },
        {
            "city": "Kuwait City",
            "latitude": "29.375",
            "longitude": "47.98",
            "country": "Kuwait",
            "iso2": "KW",
            "iso3": "KWT",
            "capital": "primary",
            "population": "637411",
            "id": "1414102075"
        },
        {
            "city": "Kingston",
            "latitude": "17.9714",
            "longitude": "-76.7931",
            "country": "Jamaica",
            "iso2": "JM",
            "iso3": "JAM",
            "capital": "primary",
            "population": "580000",
            "id": "1388709177"
        },
        {
            "city": "Vilnius",
            "latitude": "54.6833",
            "longitude": "25.2833",
            "country": "Lithuania",
            "iso2": "LT",
            "iso3": "LTU",
            "capital": "primary",
            "population": "574147",
            "id": "1440887149"
        },
        {
            "city": "San Salvador",
            "latitude": "13.6989",
            "longitude": "-89.1914",
            "country": "El Salvador",
            "iso2": "SV",
            "iso3": "SLV",
            "capital": "primary",
            "population": "567698",
            "id": "1222647454"
        },
        {
            "city": "Djibouti",
            "latitude": "11.595",
            "longitude": "43.1481",
            "country": "Djibouti",
            "iso2": "DJ",
            "iso3": "DJI",
            "capital": "primary",
            "population": "562000",
            "id": "1262028958"
        },
        {
            "city": "Lisbon",
            "latitude": "38.7452",
            "longitude": "-9.1604",
            "country": "Portugal",
            "iso2": "PT",
            "iso3": "PRT",
            "capital": "primary",
            "population": "506654",
            "id": "1620619017"
        },
        {
            "city": "Tallinn",
            "latitude": "59.4372",
            "longitude": "24.745",
            "country": "Estonia",
            "iso2": "EE",
            "iso3": "EST",
            "capital": "primary",
            "population": "434562",
            "id": "1233260021"
        },
        {
            "city": "Cape Town",
            "latitude": "-33.925",
            "longitude": "18.425",
            "country": "South Africa",
            "iso2": "ZA",
            "iso3": "ZAF",
            "capital": "primary",
            "population": "433688",
            "id": "1710680650"
        },
        {
            "city": "Bratislava",
            "latitude": "48.1447",
            "longitude": "17.1128",
            "country": "Slovakia",
            "iso2": "SK",
            "iso3": "SVK",
            "capital": "primary",
            "population": "429564",
            "id": "1703195001"
        },
        {
            "city": "Tirana",
            "latitude": "41.33",
            "longitude": "19.82",
            "country": "Albania",
            "iso2": "AL",
            "iso3": "ALB",
            "capital": "primary",
            "population": "418495",
            "id": "1008162156"
        },
        {
            "city": "Canberra",
            "latitude": "-35.2931",
            "longitude": "149.1269",
            "country": "Australia",
            "iso2": "AU",
            "iso3": "AUS",
            "capital": "primary",
            "population": "426704",
            "id": "1036142029"
        },
        {
            "city": "Wellington",
            "latitude": "-41.2889",
            "longitude": "174.7772",
            "country": "New Zealand",
            "iso2": "NZ",
            "iso3": "NZL",
            "capital": "primary",
            "population": "418500",
            "id": "1554772152"
        },
        {
            "city": "Beirut",
            "latitude": "33.8869",
            "longitude": "35.5131",
            "country": "Lebanon",
            "iso2": "LB",
            "iso3": "LBN",
            "capital": "primary",
            "population": "361366",
            "id": "1422847713"
        },
        {
            "city": "Dodoma",
            "latitude": "-6.25",
            "longitude": "35.75",
            "country": "Tanzania",
            "iso2": "TZ",
            "iso3": "TZA",
            "capital": "primary",
            "population": "410956",
            "id": "1834288497"
        },
        {
            "city": "Bissau",
            "latitude": "11.8592",
            "longitude": "-15.5956",
            "country": "Guinea-Bissau",
            "iso2": "GW",
            "iso3": "GNB",
            "capital": "primary",
            "population": "395954",
            "id": "1624168850"
        },
        {
            "city": "Juba",
            "latitude": "4.85",
            "longitude": "31.6",
            "country": "South Sudan",
            "iso2": "SS",
            "iso3": "SSD",
            "capital": "primary",
            "population": "372410",
            "id": "1728444337"
        },
        {
            "city": "Port Moresby",
            "latitude": "-9.4789",
            "longitude": "147.1494",
            "country": "Papua New Guinea",
            "iso2": "PG",
            "iso3": "PNG",
            "capital": "primary",
            "population": "364125",
            "id": "1598685395"
        },
        {
            "city": "Yamoussoukro",
            "latitude": "6.8161",
            "longitude": "-5.2742",
            "country": "Côte D’Ivoire",
            "iso2": "CI",
            "iso3": "CIV",
            "capital": "primary",
            "population": "355573",
            "id": "1384683557"
        },
        {
            "city": "Maseru",
            "latitude": "-29.31",
            "longitude": "27.48",
            "country": "Lesotho",
            "iso2": "LS",
            "iso3": "LSO",
            "capital": "primary",
            "population": "330790",
            "id": "1426977668"
        },
        {
            "city": "Nicosia",
            "latitude": "35.1725",
            "longitude": "33.365",
            "country": "Cyprus",
            "iso2": "CY",
            "iso3": "CYP",
            "capital": "primary",
            "population": "330000",
            "id": "1196944155"
        },
        {
            "city": "Windhoek",
            "latitude": "-22.57",
            "longitude": "17.0836",
            "country": "Namibia",
            "iso2": "NA",
            "iso3": "NAM",
            "capital": "primary",
            "population": "322500",
            "id": "1516802003"
        },
        {
            "city": "Porto-Novo",
            "latitude": "6.4833",
            "longitude": "2.6167",
            "country": "Benin",
            "iso2": "BJ",
            "iso3": "BEN",
            "capital": "primary",
            "population": "300000",
            "id": "1204172060"
        },
        {
            "city": "Sucre",
            "latitude": "-19.0431",
            "longitude": "-65.2592",
            "country": "Bolivia",
            "iso2": "BO",
            "iso3": "BOL",
            "capital": "primary",
            "population": "300000",
            "id": "1068823873"
        },
        {
            "city": "San José",
            "latitude": "9.9333",
            "longitude": "-84.0833",
            "country": "Costa Rica",
            "iso2": "CR",
            "iso3": "CRI",
            "capital": "primary",
            "population": "288054",
            "id": "1188749877"
        },
        {
            "city": "Ljubljana",
            "latitude": "46.05",
            "longitude": "14.5167",
            "country": "Slovenia",
            "iso2": "SI",
            "iso3": "SVN",
            "capital": "primary",
            "population": "284355",
            "id": "1705917455"
        },
        {
            "city": "Sarajevo",
            "latitude": "43.8667",
            "longitude": "18.4167",
            "country": "Bosnia And Herzegovina",
            "iso2": "BA",
            "iso3": "BIH",
            "capital": "primary",
            "population": "275524",
            "id": "1070966777"
        },
        {
            "city": "Nassau",
            "latitude": "25.0667",
            "longitude": "-77.3333",
            "country": "Bahamas, The",
            "iso2": "BS",
            "iso3": "BHS",
            "capital": "primary",
            "population": "274400",
            "id": "1044318561"
        },
        {
            "city": "Bloemfontein",
            "latitude": "-29.1",
            "longitude": "26.2167",
            "country": "South Africa",
            "iso2": "ZA",
            "iso3": "ZAF",
            "capital": "primary",
            "population": "256185",
            "id": "1710495933"
        },
        {
            "city": "Gaborone",
            "latitude": "-24.6569",
            "longitude": "25.9086",
            "country": "Botswana",
            "iso2": "BW",
            "iso3": "BWA",
            "capital": "primary",
            "population": "231626",
            "id": "1072756768"
        },
        {
            "city": "Paramaribo",
            "latitude": "5.8667",
            "longitude": "-55.1667",
            "country": "Suriname",
            "iso2": "SR",
            "iso3": "SUR",
            "capital": "primary",
            "population": "223757",
            "id": "1740518660"
        },
        {
            "city": "Dili",
            "latitude": "-8.5586",
            "longitude": "125.5736",
            "country": "Timor-Leste",
            "iso2": "TL",
            "iso3": "TLS",
            "capital": "primary",
            "population": "222323",
            "id": "1626308942"
        },
        {
            "city": "Pristina",
            "latitude": "42.6667",
            "longitude": "21.1667",
            "country": "Kosovo",
            "iso2": "XK",
            "iso3": "XKS",
            "capital": "primary",
            "population": "204725",
            "id": "1901760068"
        },
        {
            "city": "Georgetown",
            "latitude": "6.7833",
            "longitude": "-58.1667",
            "country": "Guyana",
            "iso2": "GY",
            "iso3": "GUY",
            "capital": "primary",
            "population": "200500",
            "id": "1328160906"
        },
        {
            "city": "Malabo",
            "latitude": "3.7521",
            "longitude": "8.7737",
            "country": "Equatorial Guinea",
            "iso2": "GQ",
            "iso3": "GNQ",
            "capital": "primary",
            "population": "187302",
            "id": "1226861333"
        },
        {
            "city": "Brussels",
            "latitude": "50.8467",
            "longitude": "4.3517",
            "country": "Belgium",
            "iso2": "BE",
            "iso3": "BEL",
            "capital": "primary",
            "population": "185103",
            "id": "1056469830"
        },
        {
            "city": "Male",
            "latitude": "4.175",
            "longitude": "73.5083",
            "country": "Maldives",
            "iso2": "MV",
            "iso3": "MDV",
            "capital": "primary",
            "population": "133019",
            "id": "1462441685"
        },
        {
            "city": "Podgorica",
            "latitude": "42.4397",
            "longitude": "19.2661",
            "country": "Montenegro",
            "iso2": "ME",
            "iso3": "MNE",
            "capital": "primary",
            "population": "174515",
            "id": "1499454516"
        },
        {
            "city": "Manama",
            "latitude": "26.2167",
            "longitude": "50.5831",
            "country": "Bahrain",
            "iso2": "BH",
            "iso3": "BHR",
            "capital": "primary",
            "population": "157474",
            "id": "1048989486"
        },
        {
            "city": "Port Louis",
            "latitude": "-20.1667",
            "longitude": "57.5",
            "country": "Mauritius",
            "iso2": "MU",
            "iso3": "MUS",
            "capital": "primary",
            "population": "149194",
            "id": "1480131261"
        },
        {
            "city": "New Delhi",
            "latitude": "28.7",
            "longitude": "77.2",
            "country": "India",
            "iso2": "IN",
            "iso3": "IND",
            "capital": "primary",
            "population": "142004",
            "id": "1356215164"
        },
        {
            "city": "Bern",
            "latitude": "46.948",
            "longitude": "7.4474",
            "country": "Switzerland",
            "iso2": "CH",
            "iso3": "CHE",
            "capital": "primary",
            "population": "133798",
            "id": "1756374318"
        },
        {
            "city": "Reykjavík",
            "latitude": "64.1475",
            "longitude": "-21.935",
            "country": "Iceland",
            "iso2": "IS",
            "iso3": "ISL",
            "capital": "primary",
            "population": "128793",
            "id": "1352327190"
        },
        {
            "city": "Praia",
            "latitude": "14.9177",
            "longitude": "-23.5092",
            "country": "Cabo Verde",
            "iso2": "CV",
            "iso3": "CPV",
            "capital": "primary",
            "population": "127832",
            "id": "1132398770"
        },
        {
            "city": "Luxembourg",
            "latitude": "49.6106",
            "longitude": "6.1328",
            "country": "Luxembourg",
            "iso2": "LU",
            "iso3": "LUX",
            "capital": "primary",
            "population": "122273",
            "id": "1442262731"
        },
        {
            "city": "Sri Jayewardenepura Kotte",
            "latitude": "6.9",
            "longitude": "79.9164",
            "country": "Sri Lanka",
            "iso2": "LK",
            "iso3": "LKA",
            "capital": "primary",
            "population": "115826",
            "id": "1144239330"
        },
        {
            "city": "Bridgetown",
            "latitude": "13.0975",
            "longitude": "-59.6167",
            "country": "Barbados",
            "iso2": "BB",
            "iso3": "BRB",
            "capital": "primary",
            "population": "110000",
            "id": "1052274244"
        },
        {
            "city": "Moroni",
            "latitude": "-11.7036",
            "longitude": "43.2536",
            "country": "Comoros",
            "iso2": "KM",
            "iso3": "COM",
            "capital": "primary",
            "population": "111329",
            "id": "1174793581"
        },
        {
            "city": "Thimphu",
            "latitude": "27.4833",
            "longitude": "89.6333",
            "country": "Bhutan",
            "iso2": "BT",
            "iso3": "BTN",
            "capital": "primary",
            "population": "104000",
            "id": "1064010361"
        },
        {
            "city": "Mbabane",
            "latitude": "-26.3167",
            "longitude": "31.1333",
            "country": "Swaziland",
            "iso2": "SZ",
            "iso3": "SWZ",
            "capital": "primary",
            "population": "90138",
            "id": "1748811945"
        },
        {
            "city": "Honiara",
            "latitude": "-9.4333",
            "longitude": "159.95",
            "country": "Solomon Islands",
            "iso2": "SB",
            "iso3": "SLB",
            "capital": "primary",
            "population": "84520",
            "id": "1090737486"
        },
        {
            "city": "Suva",
            "latitude": "-18.1333",
            "longitude": "178.4333",
            "country": "Fiji",
            "iso2": "FJ",
            "iso3": "FJI",
            "capital": "primary",
            "population": "88271",
            "id": "1242615095"
        },
        {
            "city": "Castries",
            "latitude": "14.0167",
            "longitude": "-60.9833",
            "country": "Saint Lucia",
            "iso2": "LC",
            "iso3": "LCA",
            "capital": "primary",
            "population": "70000",
            "id": "1662922505"
        },
        {
            "city": "São Tomé",
            "latitude": "0.3333",
            "longitude": "6.7333",
            "country": "Sao Tome And Principe",
            "iso2": "ST",
            "iso3": "STP",
            "capital": "primary",
            "population": "56166",
            "id": "1678301324"
        },
        {
            "city": "Port-Vila",
            "latitude": "-17.75",
            "longitude": "168.3",
            "country": "Vanuatu",
            "iso2": "VU",
            "iso3": "VUT",
            "capital": "primary",
            "population": "51437",
            "id": "1548805075"
        },
        {
            "city": "Bandar Seri Begawan",
            "latitude": "4.9167",
            "longitude": "114.9167",
            "country": "Brunei",
            "iso2": "BN",
            "iso3": "BRN",
            "capital": "primary",
            "population": "50000",
            "id": "1096465895"
        },
        {
            "city": "Monaco",
            "latitude": "43.7396",
            "longitude": "7.4069",
            "country": "Monaco",
            "iso2": "MC",
            "iso3": "MCO",
            "capital": "primary",
            "population": "36371",
            "id": "1492854256"
        },
        {
            "city": "Gitega",
            "latitude": "-3.4264",
            "longitude": "29.9306",
            "country": "Burundi",
            "iso2": "BI",
            "iso3": "BDI",
            "capital": "primary",
            "population": "41944",
            "id": "1108778000"
        },
        {
            "city": "Port of Spain",
            "latitude": "10.6667",
            "longitude": "-61.5167",
            "country": "Trinidad And Tobago",
            "iso2": "TT",
            "iso3": "TTO",
            "capital": "primary",
            "population": "37074",
            "id": "1780670676"
        },
        {
            "city": "Apia",
            "latitude": "-13.8333",
            "longitude": "-171.8333",
            "country": "Samoa",
            "iso2": "WS",
            "iso3": "WSM",
            "capital": "primary",
            "population": "37708",
            "id": "1882489296"
        },
        {
            "city": "Vaduz",
            "latitude": "47.1415",
            "longitude": "9.5215",
            "country": "Liechtenstein",
            "iso2": "LI",
            "iso3": "LIE",
            "capital": "primary",
            "population": "36281",
            "id": "1438317747"
        },
        {
            "city": "Banjul",
            "latitude": "13.4531",
            "longitude": "-16.5775",
            "country": "Gambia, The",
            "iso2": "GM",
            "iso3": "GMB",
            "capital": "primary",
            "population": "31356",
            "id": "1270723713"
        },
        {
            "city": "Tarawa",
            "latitude": "1.3382",
            "longitude": "173.0176",
            "country": "Kiribati",
            "iso2": "KI",
            "iso3": "KIR",
            "capital": "primary",
            "population": "28802",
            "id": "1296152641"
        },
        {
            "city": "Victoria",
            "latitude": "-4.6236",
            "longitude": "55.4544",
            "country": "Seychelles",
            "iso2": "SC",
            "iso3": "SYC",
            "capital": "primary",
            "population": "26450",
            "id": "1690193579"
        },
        {
            "city": "Majuro",
            "latitude": "7.1167",
            "longitude": "171.3667",
            "country": "Marshall Islands",
            "iso2": "MH",
            "iso3": "MHL",
            "capital": "primary",
            "population": "25400",
            "id": "1584000000"
        },
        {
            "city": "Kingstown",
            "latitude": "13.1667",
            "longitude": "-61.2333",
            "country": "Saint Vincent And The Grenadines",
            "iso2": "VC",
            "iso3": "VCT",
            "capital": "primary",
            "population": "25418",
            "id": "1670376659"
        },
        {
            "city": "Nuku‘alofa",
            "latitude": "-21.1347",
            "longitude": "-175.2083",
            "country": "Tonga",
            "iso2": "TO",
            "iso3": "TON",
            "capital": "primary",
            "population": "24571",
            "id": "1776737461"
        },
        {
            "city": "Saint John’s",
            "latitude": "17.1211",
            "longitude": "-61.8447",
            "country": "Antigua And Barbuda",
            "iso2": "AG",
            "iso3": "ATG",
            "capital": "primary",
            "population": "21926",
            "id": "1028912067"
        },
        {
            "city": "Andorra la Vella",
            "latitude": "42.5",
            "longitude": "1.5",
            "country": "Andorra",
            "iso2": "AD",
            "iso3": "AND",
            "capital": "primary",
            "population": "22151",
            "id": "1020828846"
        },
        {
            "city": "Belmopan",
            "latitude": "17.25",
            "longitude": "-88.7675",
            "country": "Belize",
            "iso2": "BZ",
            "iso3": "BLZ",
            "capital": "primary",
            "population": "17222",
            "id": "1084586375"
        },
        {
            "city": "Roseau",
            "latitude": "15.3",
            "longitude": "-61.3833",
            "country": "Dominica",
            "iso2": "DM",
            "iso3": "DMA",
            "capital": "primary",
            "population": "16582",
            "id": "1212060440"
        },
        {
            "city": "Basseterre",
            "latitude": "17.2983",
            "longitude": "-62.7342",
            "country": "Saint Kitts And Nevis",
            "iso2": "KN",
            "iso3": "KNA",
            "capital": "primary",
            "population": "13220",
            "id": "1659198919"
        },
        {
            "city": "Pago Pago",
            "latitude": "-14.274",
            "longitude": "-170.7046",
            "country": "American Samoa",
            "iso2": "AS",
            "iso3": "ASM",
            "capital": "primary",
            "population": "12576",
            "id": "1016976740"
        },
        {
            "city": "Valletta",
            "latitude": "35.8978",
            "longitude": "14.5125",
            "country": "Malta",
            "iso2": "MT",
            "iso3": "MLT",
            "capital": "primary",
            "population": "6444",
            "id": "1470574399"
        },
        {
            "city": "Palikir",
            "latitude": "6.9178",
            "longitude": "158.185",
            "country": "Micronesia, Federated States Of",
            "iso2": "FM",
            "iso3": "FSM",
            "capital": "primary",
            "population": "6227",
            "id": "1583008885"
        },
        {
            "city": "Funafuti",
            "latitude": "-8.5167",
            "longitude": "179.2167",
            "country": "Tuvalu",
            "iso2": "TV",
            "iso3": "TUV",
            "capital": "primary",
            "population": "6025",
            "id": "1798251391"
        },
        {
            "city": "Lobamba",
            "latitude": "-26.4167",
            "longitude": "31.1667",
            "country": "Swaziland",
            "iso2": "SZ",
            "iso3": "SWZ",
            "capital": "primary",
            "population": "5800",
            "id": "1748963140"
        },
        {
            "city": "Saint George’s",
            "latitude": "12.0444",
            "longitude": "-61.7417",
            "country": "Grenada",
            "iso2": "GD",
            "iso3": "GRD",
            "capital": "primary",
            "population": "4315",
            "id": "1308891766"
        },
        {
            "city": "San Marino",
            "latitude": "43.932",
            "longitude": "12.4484",
            "country": "San Marino",
            "iso2": "SM",
            "iso3": "SMR",
            "capital": "primary",
            "population": "4040",
            "id": "1674741947"
        },
        {
            "city": "Capitol Hill",
            "latitude": "15.2137",
            "longitude": "145.7546",
            "country": "Northern Mariana Islands",
            "iso2": "MP",
            "iso3": "MNP",
            "capital": "primary",
            "population": "2500",
            "id": "1580556972"
        },
        {
            "city": "Hagåtña",
            "latitude": "13.4745",
            "longitude": "144.7504",
            "country": "Guam",
            "iso2": "GU",
            "iso3": "GUM",
            "capital": "primary",
            "population": "1051",
            "id": "1316937540"
        },
        {
            "city": "Ngerulmud",
            "latitude": "7.5006",
            "longitude": "134.6242",
            "country": "Palau",
            "iso2": "PW",
            "iso3": "PLW",
            "capital": "primary",
            "id": "1585525081"
        }
    ];