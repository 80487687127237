import React from "react";
import SolutionImg from "../assets/img/Solutions/solution-img.png";
import PageTitleContent from "../components/Cards/PageTitleContent";
import Solution from "../components/Cards/Solution";

export default function Solutions() {
  return (
    <>
      <PageTitleContent headline="Solutions" title="Solutions" />

      <section class="solution-area pt-100 pb-70">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6">
              <div class="solution-content">
                <div class="solution-title">
                  <span>All-in Solution</span>
                  <h2>
                    Our experts are providing and planning 24/7 technical
                    support services with product and security consultation.
                  </h2>
                </div>

                <div class="row">
                  <Solution
                    title="Dark Web Monitoring"
                    to="/dark-web-monitoring"
                    description="ThreatsEye, The intelligence-driven data protection solution that can detect and erase traces of stolen data."
                    numerotation="01"
                  />
                  <Solution
                    title="Spam Domain Tracking"
                    to="/spam-domain-tracking"
                    description="Your website’s domain name is the most important part of your digital identity. Protecting it is worth the investment."
                    numerotation="02"
                  />
                  <Solution
                    title="Data Breach Alerting"
                    to="/data-breach-alerting"
                    description="The internet has made it easier for hackers to steal your informations and we need to take action now."
                    numerotation="03"
                  />
                  <Solution
                    title="Live Cyber Attacks"
                    to="/live-cyber-attacks"
                    description="Get real-time information about cyber attacks as they occur."
                    numerotation="04"
                  />
                </div>
              </div>
            </div>

            <div class="col-lg-6 pr-0">
              <div
                className="solution-img"
                style={{ backgroundImage: `url(${SolutionImg})` }}
              >
                <img src={SolutionImg} alt="Solutions" loading="lazy" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
