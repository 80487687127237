import data1 from './1.json';
import data2 from './2.json';
import data3 from './3.json';
import data4 from './4.json';
import data5 from './5.json';
import data6 from './6.json';
import data7 from './7.json';
import data8 from './8.json';
import data9 from './9.json';
import data10 from './10.json';
import data11 from './11.json';
import data12 from './12.json';
import data13 from './13.json';
import data14 from './14.json';
import data15 from './15.json';
import data16 from './16.json';
import data17 from './17.json';
import data18 from './18.json';
import data19 from './19.json';
import data20 from './20.json';
import data21 from './21.json';
import data22 from './22.json';
import data23 from './23.json';
import data24 from './24.json';
import data25 from './25.json';
import data26 from './26.json';
import data27 from './27.json';
import data28 from './28.json';
import data29 from './29.json';
import data30 from './30.json';
export const data = [
    [
        data1,
        data2,
        data3,
        data4,
        data5,
        data6,
        data7,
        data8,
        data9,
        data10,
        data11,
        data12,
        data13,
        data14,
        data15,
        data16,
        data17,
        data18,
        data19,
        data20,
        data21,
        data22,
        data23,
        data24,
        data25,
        data26,
        data27,
        data28,
        data29,
        data30,
    ]
]