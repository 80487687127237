import React from 'react'
import { Link } from 'react-router-dom'
import Image from '../../assets/img/Approch/approach-img.jpg'
import PageTitleContent from '../../components/Cards/PageTitleContent'


const DataBreach = () => {
  return (
    <>
		<PageTitleContent headline="Data Breach Alerting" title="Solution Details" />
        <section className="solution-details-area ptb-100">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6">
						<div className="solutions-content">
							<h3>Data breaches</h3>
							<p>Data breaches are on the rise! In the past year alone, major security breaches have compromised more than 1 billion users' data. With our data breach alerting system, companies can keep tabs on potential threats to their data easily and securely. </p>

							<p>Data breaches are the worst nightmare for business owners. They can result in a loss of customer trust, brand reputation, and revenue. But data breaches are hard to spot until it's too late - that's why we created Data Breach Alerting, the ultimate solution for companies looking to safeguard their data. With our service, you'll get an instant notification if your database is hacked or compromised and be able to take immediate action.</p>
						</div>
					</div>

					<div className="col-lg-6">
						<div className="solution-details-one">
							<img src={Image} alt="Solution" />
						</div>
					</div>

					<div className="col-lg-12">
						<div className="solutions-content two">
							<h3>Our Data Breach Alerting solution</h3>

							<p>Our Data Breach Alerting solution is the world's first and only 100% automated solution that notifies you instantly of any data breaches when they happen. Our solution is a security alert system that allows companies to be automatically alerted when their data has been breached. We help companies quickly identify the issue and take appropriate action to protect themselves and their customers. Thousands of companies have already used our product, and they've rated us 4.7/5 across all reviews.</p>
	
							
						</div>
					</div>

					<div className="col-lg-12">
						<div className="solutions-content four">
							<div className="challenge-btn">
								<Link to="/contact" className="default-btn overly-one">
									<span>Talk to expert</span>
								</Link>

								<Link to="/request-demo" className="default-btn overly-one two">
									<span>Request Demo</span>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    </>
  )
}

export default DataBreach