import React from "react";
import { useParams } from "react-router-dom";
import PageTitleContent from "../components/Cards/PageTitleContent";
import OrderForm from "../components/Forms/OrderForm";


function Order() {
  const { plan } = useParams();
  return (
    <>
      <PageTitleContent headline="Order plan" title="Details" />

      <section className="user-area-style ptb-100">
        <div className="container">
          <div className="contact-form-action">
            <div className="account-title">
              <h2>Plan: {plan} </h2>
            </div>
            <OrderForm />
          </div>
        </div>
      </section>
    </>
  );
}

export default Order;
