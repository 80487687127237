import React from "react";

const CardHeader = ({ title, icon }) => {
  return (
    <div className="p-2 cursor-pointer bg-gray-50 dark:bg-gray-700">
      <h5 className="text-xs text-white flex items-center">
        {icon}
        {title}
      </h5>
    </div>
  );
};

export default CardHeader;
