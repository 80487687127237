import React from "react";

export default function CopyrightAreaContainer({children}) {
  return (
    <div className="copy-right-area">
      <div className="container">
        <div className="row align-items-center">{children}</div>
      </div>
    </div>
  );
}
