import React from 'react'
import { Link } from 'react-router-dom'

export default function FooterLinksItem({to, ...props}) {
  return (
    <li>
        <Link to={to}>{props.title}</Link>
    </li>
  )
}
