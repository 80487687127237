import React from 'react'
import Presentation from '../components/Sections/Presentation'
import Features from '../components/Sections/Features'
import Services from '../components/Sections/Services'
import Approaches from '../components/Sections/Approaches'
import GetInTouch from '../components/Sections/GetInTouch'
import Solutions from '../components/Sections/Solutions'
import WhyChooseUs from '../components/Sections/WhyChooseUs'

const Home = () => {
  return (
    <>
        <Presentation />
        <Features />
        <Services />
        <Solutions />
        <WhyChooseUs />
        <Approaches />
        <GetInTouch />
        
    </>
  )
}
export default Home