import React from 'react'
import FaqImg from "../assets/img/Faq/faq-img.png";
import FaqRightShapeImg from "../assets/img/Faq/faq-right-shape.png";
import PageTitleContent from '../components/Cards/PageTitleContent';

const Faq = () => {
  return (
    <>
    <PageTitleContent headline="FAQ" title="Frequently asked questions" />
    <section className="faq-area white-bg ptb-100">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6">
						<div className="faq-img">
							<img src={FaqImg} alt="Image" loading="lazy"/>
						</div>
					</div>

					<div className="col-lg-6">
						<div className="faq-accordion">
							<div className="faq-title">
								<h2>Frequently asked questions</h2>
								<span>Just find your answers below</span>
							</div>

							<ul className="accordion">
								<li className="accordion-item">
									<a className="accordion-title active" href="javascript:void(0)">
										<i className="bx bx-plus"></i>
										What is a managed security service?
									</a>
		
									<div className="accordion-content show">
										<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui in, illo blanditiis quisquam quam asperiores veritatis, earum quibusdam laudantium distinctio dolore placeat recusandae ratione maxime odit delectus nobis doloribus quia Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui in, illo blanditiis</p>
									</div>
								</li>
		
								<li className="accordion-item">
									<a className="accordion-title active" href="javascript:void(0)">
										<i className="bx bx-plus"></i>
										Why do we need cybersecurity?
									</a>
		
									<div className="accordion-content show">
										<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui in, illo blanditiis quisquam quam asperiores veritatis, earum quibusdam laudantium distinctio dolore placeat recusandae ratione maxime odit delectus nobis doloribus quia Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui in, illo blanditiis</p>
									</div>
								</li>
		
								<li className="accordion-item">
									<a className="accordion-title active" href="javascript:void(0)">
										<i className="bx bx-plus"></i>
										What is theaa best way to learn cybersecurity?
									</a>
		
									<div className="accordion-content show">
										<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui in, illo blanditiis quisquam quam asperiores veritatis, earum quibusdam laudantium distinctio dolore placeat recusandae ratione maxime odit delectus nobis doloribus quia Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui in, illo blanditiis</p>
									</div>
								</li>
		
								<li className="accordion-item">
									<a className="accordion-title active" href="javascript:void(0)">
										<i className="bx bx-plus"></i>
										How do i secure my computer?
									</a>
		
									<div className="accordion-content show">
										<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui in, illo blanditiis quisquam quam asperiores veritatis, earum quibusdam laudantium distinctio dolore placeat recusandae ratione maxime odit delectus nobis doloribus quia Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui in, illo blanditiis</p>
									</div>
								</li>
		
								<li className="accordion-item">
									<a className="accordion-title active" href="javascript:void(0)">
										<i className="bx bx-plus"></i>
										What are theaa costs of a cyber attack?
									</a>
		
									<div className="accordion-content show">
										<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui in, illo blanditiis quisquam quam asperiores veritatis, earum quibusdam laudantium distinctio dolore placeat recusandae ratione maxime odit delectus nobis doloribus quia Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui in, illo blanditiis</p>
									</div>
								</li>

								<li className="accordion-item">
									<a className="accordion-title active" href="javascript:void(0)">
										<i className="bx bx-plus"></i>
										How can i safely browse the internet?
									</a>
		
									<div className="accordion-content show">
										<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui in, illo blanditiis quisquam quam asperiores veritatis, earum quibusdam laudantium distinctio dolore placeat recusandae ratione maxime odit delectus nobis doloribus quia Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui in, illo blanditiis</p>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div className="faq-right-shape shape-three">
				<img src={FaqRightShapeImg} alt="Image" loading='lazy'/>
			</div>
		</section>
    
    </>
  )
}

export default Faq