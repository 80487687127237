import React from 'react';

const CountryCard = ({ country_code, country_name }) => {
  const flagIconUrl = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/flags/4x3/${country_code}.svg`;

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          aria-label={country_code}
          role="img"
          title={country_code}
          style={{
            position: 'relative',
            display: 'inline-block',
            width: '25px',
            height: '25px',
            backgroundImage: `url(${flagIconUrl})`,
            backgroundPosition: '50% center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            fontSize: '1em',
            verticalAlign: 'middle',
            marginRight: '0.5em',
          }}
        ></span>
        <span className='text-white'>{country_name}</span>
      </div>
      <div style={{ height: '0.5rem' }} />
    </div>
  );
};

export default CountryCard;
