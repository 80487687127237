import React from "react";
import Approach4 from "../../assets/img/Approch/approach-img-4.jpg";
import Approach from "../Cards/Approach";
import SectionTitle from "./SectionTitle";

export default function Approaches() {
  return (
    <section className="our-approach-area our-approach-area-four pb-70  pt-100">
      <div className="container">
        <SectionTitle
          title="Our Approach"
          description="To Enhance Your Cyber Defences Expert Will Support"
        />

        <div className="row align-items-center row-eq-height">
          <div className="col-lg-6">
            <div className="banner-image">
              <img src={Approach4} alt="Approach" loading="lazy" />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="approach-content">
              <h3>
                We aim to renew the report each year to offer analyses that
                build upon past reports and break new ground.
              </h3>

              <div className="row">
                <Approach
                  title="$4.35M"
                  description="Global average total cost of a data breach in 2022."
                />
                <Approach
                  title="$4.54M"
                  description="Average cost of a ransomware attack."
                />
                <Approach title="824" description="Breached organizations." />
                <Approach
                  title="280 days"
                  description="Average time to identify and contain."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
