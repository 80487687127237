import React from "react";
import { Link } from "react-router-dom";

export default function RequestDemoMobile() {
  return (
    <div className="option-inner">
      <div className="others-option justify-content-center d-flex align-items-center">
        <div className="">
          <Link to="/request-demo" className="default-btn">
            <span>Request a demo</span>
          </Link>
        </div>
      </div>
      
    </div>
  );
}
