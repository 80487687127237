import React from "react";

export default function MainNavContainer({ children }) {
  return (
    <div className="main-nav">
      <div className="container">
        <nav className="navbar navbar-expand-md">{children}</nav>
      </div>
    </div>
  );
}
