import React from "react";
import { Link } from "react-router-dom";

export default function PageTitleContent(props) {
  return (
    <div className="page-title-area page-title-style-two">
      <div className="container">
        <div className="page-title-content">
          <h2>{props.headline}</h2>
          <ul>
            <li>
              <Link to="/">
                <i className="bx bx-home"></i> Home
              </Link>
            </li>
            
            {props.title && <li className="active">{props.title}</li>}
          </ul>
        </div>
      </div>
    </div>
  );
}
