import React from "react";

export default function SectionTitle(props) {
  return (
    <div className="section-title">
      <span>{props.title}</span>
      <h2>{props.description}</h2>
    </div>
  );
}
