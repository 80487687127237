import React from 'react'
import { Link } from 'react-router-dom'
import Image from '../../assets/img/ThreatsEye-Screen-map.png'
import PageTitleContent from '../../components/Cards/PageTitleContent'

const LiveCyberAttacks = () => {
  return (
    <>
	<PageTitleContent headline="Live Cyber Attacks" title="Solution Details" />
		
        <section className="solution-details-area ptb-100">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6">
						<div className="solutions-content">
							<h3>A glimpse about Cyber attacks</h3>

							<p>Cyber attacks are on the rise and it has become a global issue. In past few years, we witnessed an escalation in ransomware attacks and malware. We have to also keep a track on how hackers are finding new ways of attacking technology which might not be as secure as they would like them to be.</p>

							<p>The world has never been so susceptible to cyber attacks. These live cyberattacks can put any device with internet connection at risk. The next time when you will think twice before downloading an app or opening an attachment that you don’t know where it came from.</p>

							<p>Cyber attacks have been increasing in the last couple of years. These attacks are becoming more and more complicated and sophisticated, as hackers get better at their craft. However, there are several steps to take in order to protect a company's digital assets. Among these steps, is training employees on how to prevent cyberattacks and being aware of what looks suspicious.⁣</p>
						</div>
					</div>

					<div className="col-lg-6">
						<div className="">
							<img src={Image} alt="Cyber Threats Map" />
						</div>
					</div>

					<div className="col-lg-12">
						<div className="solutions-content two">
							<h3>Live Cyber Attacks Feeds</h3>
							
							<p>The internet is a dangerous place, and it is only getting more so. But unlike the past, where people were really at the mercy of cyber criminals and hacking groups, we now have a solution: Live Cyber Attacks. Live Cyber Attacks is an AI-powered dashboard that monitors your network 24x7x365 and alerts you to any potential threats. It also comes with real-time threat intelligence updates delivered by our highly-trained team of cybersecurity experts. This way, you can always be prepared for any attack</p>
	
						</div>
					</div>


					<div className="col-lg-12">
						<div className="solutions-content four">
							<div className="challenge-btn">
								<Link to="/contact" className="default-btn overly-one">
									<span>Talk to expert</span>
								</Link>

								<Link to="/request-demo" className="default-btn overly-one two">
									<span>Request Demo</span>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    </>
  )
}

export default LiveCyberAttacks