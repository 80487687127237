import React from "react";
import { Link } from "react-router-dom";
import PageTitleContent from "../components/Cards/PageTitleContent";
import ContactForm from "../components/Forms/ContactForm";
import SocialMediaItems from "../components/Layouts/NavbarMenuItems/SocialMediaItems";


const Contact = () => {

  
  return (
    <>
      <PageTitleContent headline="Contact Us" title="Contact" />

      <section className="main-contact-area ptb-100 plr-50">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="contact-wrap">
                <div className="contact-form">
                  <div className="contact-title">
                    <h2>Write Us </h2>
                  </div>
                  <ContactForm />
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="contact-info">
                <h3>Our contact details</h3>
                <ul className="address">
                  <li>
                    <i className="bx bxs-phone-call"></i>
                    <span>Phone</span>
                    <a href="tel:+212537203977">+212 537 203 977</a>
                  </li>
                  <li>
                    <i className="bx bxs-envelope"></i>
                    <span>Email</span>
                    <Link to="/">
                      <span className="__cf_email__">
                        contact@threatseye.com
                      </span>
                    </Link>
                  </li>
                </ul>

                <div className="sidebar-follow-us">
                  <h3>Follow us:</h3>

                  <ul className="social-wrap">
                    <SocialMediaItems to="/" icon="bx bxl-twitter" />
                    <SocialMediaItems to="/" icon="bx bxl-instagram" />
                    <SocialMediaItems to="/" icon="bx bxl-facebook" />
                    <SocialMediaItems to="/" icon="bx bxl-youtube" />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="map-area"></div>
    </>
  );
};

export default Contact;
