import React from 'react'
import { Link } from 'react-router-dom'

export default function RequestDemo() {
  return (
    <div className="others-option">
        <div className="get-quote">
            <Link to="/request-demo" className="default-btn">
                <span>Request a demo</span>
            </Link>
        </div>
    </div>
  )
}
