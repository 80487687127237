import React, { useEffect } from "react";
import GetInTouchForm from "../Forms/GetInTouchForm";
import GetInTouchImg from "../../assets/img/get-in-touch-shape.png";
import SectionTitle from "./SectionTitle";
const GetInTouch = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
  });

  return (
    <section className="get-in-touch-area get-in-touch-area-two bg-color ptb-100">
      <div className="container">
        <SectionTitle
          title="Get In Touch"
          description="Contact Us Today To Speak With An Expert About ThreatsEye"
        />
        <GetInTouchForm />
      </div>
      <div class="get-in-touch-shape">
        <img src={GetInTouchImg} alt="GetInTouch" />
      </div>
    </section>
  );
};

export default GetInTouch;
