import React, { useState, useEffect } from "react";
import PageTitleContent from "../../components/Cards/PageTitleContent";
import { Card, AspectRatio, Typography, Skeleton } from "@mui/joy";
import { data } from "./data.js";

const getResume = (text, count) => {
  // Remove HTML tags using a regular expression
  const plainText = text.replace(/<\/?[^>]+(>|$)/g, "");

  // Split the text into words
  const words = plainText.split(/\s+/);

  // Get the first count words and join them back into a string
  const first10Words = words.slice(0, count).join(" ");

  return `${first10Words} ...`;
};

const ContentLoader = () => {
  return (
    <Card
      variant="outlined"
      sx={{ width: 396, height: 417, display: "flex", gap: 2 }}>
      <AspectRatio ratio="21/9">
        <Skeleton variant="overlay">
          <img
            alt=""
            src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
          />
        </Skeleton>
      </AspectRatio>
      <Typography>
        <Skeleton>
          lorem ipsum dolor sit amet, consect et nesciunt et justo sed diam non
          pro id elit. lorem ipsum dolor sit amet, consect et nesciunt et justo
          sed diam non pro id elit. lorem ipsum dolor sit amet, consect et
          nesciunt et justo sed diam non pro id elit.
        </Skeleton>
      </Typography>
    </Card>
  );
};

const Blog = () => {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate data fetching
    setTimeout(() => {
      setContent(data);
      setLoading(false);
    }, 2000); // 2 seconds delay
  }, []);
  return (
    <>
      <PageTitleContent headline="Latest Cyber Security " title="Blog" />

      <section className="blog-area ptb-100">
        <div className="container">
          <div className="section-title">
            <h2>Latest News From ThreatsEye</h2>
          </div>

          <div className="row">
            {loading ? (
              <>
                <div className="col-lg-4 col-sm-6">
                  <ContentLoader />
                </div>
                <div className="col-lg-4 col-sm-6">
                  <ContentLoader />
                </div>
                <div className="col-lg-4 col-sm-6">
                  <ContentLoader />
                </div>
              </>
            ) : (
              <>
                {content && content.length > 0 ? (
                  <>
                    {content.map((item, index) => (
                      <div className="col-lg-4 col-sm-6" key={item.id}>
                        <div className="single-blog">
                          <img
                            src={item.screenshot}
                            alt="Description of the image"
                          />
                          <div className="blog-content">
                            <h3>
                              <a href={`/blog/${item.slug}`}>{item.title}</a>
                            </h3>
                            <p>{getResume(item.description, 10)}</p>
                            <a href={`/blog/${item.slug}`} className="read-more">
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div>No data found</div>
                )}
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
