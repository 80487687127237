import React from "react";
import SingleCounter from "../Cards/SingleCounter";
import Banner from '../../assets/img/counter-bg.jpg'
export default function WhyChooseUs() {
  return (
    <section className="counter-area pt-100 pb-70" style={{ backgroundImage: `url(${Banner})` }}>
      <div className="container">
        <div className="counter-max-wide">
          <div className="section-title white-title">
            <span>Why Choose Us</span>
            <h2>
              We have a lot of skills in cyber security. We have a group of
              fighters who are always working on cyber security.
            </h2>
          </div>

          <div className="row">
           
              <SingleCounter
                cols="col-lg-6 col-sm-6"
                icon="flaticon-authentication"
                number="365"
                target=""
                title="Clients Protection"
              />
            
           
              <SingleCounter
                cols="col-lg-6 col-sm-6"
                icon="flaticon-reliability"
                number="1000"
                target=""
                title="Trusted Organizations"
              />
            
           
              <SingleCounter
                cols="col-lg-6 col-sm-6"
                icon="flaticon-web-protection"
                number="567"
                target=""
                title="Website Protection"
              />
            
           
              <SingleCounter
                cols="col-lg-6 col-sm-6"
                icon="flaticon-innovation"
                number="100"
                target="%"
                title="Innovative Technology"
              />
            
          </div>
        </div>
      </div>
    </section>
  );
}
