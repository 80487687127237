import React from "react";
import CountUp from "react-countup";

const ThreatsMapHeader = ({ totalAttacks, text }) => {
  let dateRangeText = "";

  switch (text) {
    case "last_24_hours":
      dateRangeText = "Last 24 hours";
      break;
    case "last_3_days":
      dateRangeText = "Last 3 days";
      break;
    case "last_week":
      dateRangeText = "Last week";
      break;
    case "last_month":
      dateRangeText = "Last month";
      break;
    default:
      dateRangeText = "Last 24 hours";
  }

  return (
    <div className=" mx-auto">
      <div className="text-center ">
        <div className="relative flex flex-col items-center">
          <h1 className="text-3xl font-bold leading-tight text-white">
            LIVE CYBER THREAT ATTACKS
          </h1>
          <h4 className="text-lg font-bold leading-tight  uppercase mt-5">
            <span className="text-white" style={{ fontSize: "35px" }}>
              <CountUp end={totalAttacks} />
            </span>
            <br />
            <span style={{ color: "rgb(248 113 113)" }}>Attacks on</span>{" "}
            <span style={{ color: "rgb(220 38 38)" }}>{dateRangeText}</span>
          </h4>
          <div className="flex mt-2 mb-2 overflow-hidden rounded w-24">
            <div className="flex-1 h-2 bg-red-200"></div>
            <div className="flex-1 h-2 bg-red-400"></div>
            <div className="flex-1 h-2 bg-red-600"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreatsMapHeader;
