import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../../assets/img/Logo/logo-dark-full.png'
export default function NavbarBrand() {
  return (
    <Link to="/" className="navbar-brand" >
        <img src={Logo} alt="Logo" />
    </Link>
  )
}
