import React from "react";

export default function AddressItem(props) {
  return (
    <li>
      <i className={props.icon}></i>
      {props.title}
    </li>
  );
}
