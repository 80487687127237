import React from "react";
import { Link } from "react-router-dom";
import PageTitleContent from '../components/Cards/PageTitleContent'
export default function E404() {
  return (
    <>
      <PageTitleContent headline="Page Not found" title="Page Not found" />
      <div className="error-area ptb-100">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="error-content">
              <h1>
                <span className="a">4</span> <span className="red">0</span>{" "}
                <span className="b">4</span>{" "}
              </h1>
              <h3>Oops! Page Not Found</h3>
              <p>The page you were looking for could not be found.</p>
              <Link to="/" className="default-btn two">
                <span>Return to home page</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
