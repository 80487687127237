import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { ReCAPTCHA } from "react-google-recaptcha";

const RequestDemoForm = () => {
  const [phone, setPhone] = useState();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const form = useRef();
  const [loading, setLoading] = useState(false);
  const reCAPTCHA_CLIENT_ID = "6LcRpjsmAAAAAMvPFjxpNma4ThQmfy12782-XHZ7";
  const recaptchaRef = useRef();

  const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const TEMPLATE_ID = process.env.REACT_APP_REQUEST_DEMO_TEMPLATE_ID;
  const PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

  const onSubmit = async (data) => {
    setLoading(true);

    const MySwal = withReactContent(Swal);

    try {
      const token = await recaptchaRef.current.executeAsync();
      data["g-recaptcha-response"] = token;

      emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY).then(
        (result) => {
          MySwal.fire(
            "Success!",
            "The Demo request has been sent successfully.",
            "success"
          );
          reset();
        },
        (error) => {
          MySwal.fire("Error!", "Ooops, something went wrong.", "error");
        }
      );
    } catch (error) {
      MySwal.fire("Error!", "Ooops, something went wrong.", "error");
    }finally{
      setLoading(false);
    }
    return false;
  };

  return (
    <form ref={form} onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register("plan_name", { required: false })} />
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>Full name</label>
            <input
              className="form-control"
              {...register("user_name", { required: true })}
            />
            {errors.user_name && (
              <span className="help-block with-errors">
                This field is required
              </span>
            )}
          </div>
        </div>

        <div className="col-12">
          <div className="form-group">
            <label>Business Email</label>
            <input
              className="form-control"
              {...register("user_email", {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              })}
            />
            {errors.user_email && (
              <span className="help-block with-errors">
                A valid Business Email is required.
              </span>
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label>Company/Organization</label>
            <input
              className="form-control"
              {...register("company", { required: true })}
            />
            {errors.company && (
              <span className="help-block with-errors">
                This field is required
              </span>
            )}
          </div>
        </div>

        <div className="col-12">
          <div className="form-group">
            <label>Phone Number</label>
            <PhoneInput
              placeholder="Enter phone number"
              className="form-control"
              {...register("phone", { required: true })}
              value={phone}
              onChange={setPhone}
            />

            {errors.phone && (
              <span className="help-block with-errors">
                This field is required
              </span>
            )}
          </div>
        </div>

        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-6 col-sm-6">
              <div style={{ margin: "10px" }}>
                <ReCAPTCHA ref={recaptchaRef} sitekey={reCAPTCHA_CLIENT_ID} />
              </div>

              <button type="submit" className="default-btn btn-two">
                {loading ? "Submitting..." : "Submit Request"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default RequestDemoForm;
