import React from "react";
import PageTitleContent from "../../components/Cards/PageTitleContent";
import { useParams } from "react-router-dom";
import { data } from "./data.js"; // Adjust the import path as necessary

const BlogDetail = () => {
  const { slug } = useParams();
  // Find the blog post by its slug
  const blogPost = data.find((item) => item.slug === slug);

  return (
    <>
      <PageTitleContent
        headline={blogPost ? blogPost.title : "Latest Cyber Security"}
        title={blogPost ? blogPost.title : ""}
      />

      <section className="blog-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="blog-details-desc">
                {!blogPost ? (
                  <div className="d-flex justify-content-center">
                    <img src="/assets/img/not-found.png" alt="Not found" />
                  </div>
                ) : (
                  <div className="article-content">
                    <h1 className="mb-3 mt-3">{blogPost.title}</h1>

                    <div class="row mb-3">
                      <div class="col-md-12 post-header-line">
                        <span class="glyphicon glyphicon-user"></span>Published
                        by <a href="#">ThreatsEye</a> |{" "}
                        {new Date(blogPost.createdAt).toLocaleDateString()}
                      </div>
                    </div>

                    <div className="row ">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: blogPost.description,
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetail;
