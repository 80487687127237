import React from 'react'
import { Link } from 'react-router-dom'
import Image from '../../assets/img/Approch/approach-img-4.jpg'
import PageTitleContent from '../../components/Cards/PageTitleContent'

const DomainMonitoring = () => {
  return (
    <>
	<PageTitleContent headline="Spam Domain Tracking" title="Solution Details" />
        
        <section className="solution-details-area ptb-100">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6">
						<div className="solutions-content">
							<h3>Spam Domain Tracking</h3>
							
							<p>Domain hijacking and spam has become a major problem, costing companies billions of dollars every year. In response, our team has developed a new service that monitors your domain and notifies you immediately when you are at risk. The tool is comprehensive and easy to use. Customers report that they are able to respond more quickly to domain hijackers, saving them money.</p>

							<p>Domain Tracking is a service that alerts you when your domain name has been registered with a spammy TLD (top-level domain) like ".biz" or ".site". It's the world's first service to track the registration of domains with spammy TLDs.</p>

						</div>
					</div>

					<div className="col-lg-6">
						<div className="solution-details-one">
							<img src={Image} alt="Solution" />
						</div>
					</div>

					<div className="col-lg-12">
						<div className="solutions-content two">
							<h3>{process.env.REACT_APP_NAME} Spam Domain Tracking Solution</h3>
							
							<p>Our Spam Domain Tracking solution enables you to maintain a watch list of the domains that you are monitoring. {process.env.REACT_APP_NAME} will notify you when any of the domains on the list change, and you'll get alerted instantly.</p>
						</div>
					</div>

					

					<div className="col-lg-12">
						<div className="solutions-content four">
							
							<div className="challenge-btn">
								<Link to="/contact" className="default-btn overly-one">
									<span>Talk to expert</span>
								</Link>

								<Link to="/request-demo" className="default-btn overly-one two">
									<span>Request Demo</span>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    </>
  )
}

export default DomainMonitoring