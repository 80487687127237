import React from 'react'

export default function Approach(props) {
  return (
    <div className="col-lg-6 col-sm-6">
        <div className="single-approach">
        <h3>{props.title}</h3>
        <p>{props.description}</p>
        </div>
    </div>
  )
}
