import React from "react";
import NavbarListItems from "../NavbarMenuItems/NavbarListItems";
import NavbarListContainer from "../NavbarMenuItems/NavbarListContainer";

export default function MobileNavbar() {

  return (
    <nav class="mean-nav">
      <NavbarListContainer>
        <NavbarListItems to="/" text="Home" />
        <NavbarListItems to="/blog" text="Blog" />
        <NavbarListItems to="/about" text="About" />
        <NavbarListItems to="/solutions" text="Solutions" />
        <NavbarListItems to="/getquote" text="Get a Quote" />
        <NavbarListItems to="/request-demo" text="Request a demo" />
        <NavbarListItems to="/cyber-threats-map" text="Cyber Threats Map" />
        <NavbarListItems to="/contact" text="Contact" />
      </NavbarListContainer>
    </nav>
  );
}
