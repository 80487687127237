import React, { Component } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";
import Banner from "../../assets/img/banner/banner-bg-4.jpg";
import Video from "../../assets/video/threatseye-video-presentation.mp4";
import SolutionImg from "../../assets/img/ThreatsEye-Screen.png";

class Presentation extends Component {
  constructor(props) {
    super(props);

    this.state = { toggleVideo: false };
    this.handleShowVideoClick = this.handleShowVideoClick.bind(this);
    this.handleHideVideoClick = this.handleHideVideoClick.bind(this);
  }
  handleShowVideoClick() {
    this.setState(() => ({ toggleVideo: true }));
  }

  handleHideVideoClick() {
    this.setState(() => ({ toggleVideo: false }));
  }

  componentDidMount() {
    new WOW.WOW({ live: false }).init();
  }

  render() {
    return (
      <section
        className="banner-area banner-area-four bg-4"
        style={{ backgroundImage: `url(${Banner})` }}>
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="banner-content">
                    <span
                      className="top-title wow fadeInDown"
                      data-wow-delay="1s"></span>
                    <h1 className="wow fadeInDown" data-wow-delay="1s">
                      <span style={{ color: "#E21F36" }}>T</span>hreats
                      <span style={{ color: "#E21F36" }}>E</span>ye
                    </h1>
                    <p
                      className="wow fadeInLeft"
                      data-wow-delay="1.5s"
                      style={{ fontSize: "20px" }}>
                      ThreatsEye is the leading provider of web data. It
                      converts a massive amount of online data from the open and
                      dark webs into feeds, ready to consume.
                    </p>
                    <div
                      className="banner-btn wow fadeInUp"
                      data-wow-delay="1s">
                      <Link to="/contact" className="default-btn">
                        <span>Contact Us</span>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div
                    className="banner-video wow zoomIn"
                    data-wow-delay="1s"
                    style={{
                      visibility: "visible",
                      animationDelay: "1s",
                      animationName: "zoomIn",
                      position: "relative", // Add position relative to the parent div
                    }}>
                    <img src={SolutionImg} alt="Solutions" loading="lazy" />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)", // Center the button using absolute positioning and transform
                      }}>
                      <button
                        onClick={this.handleShowVideoClick}
                        className="video-btn popup-youtube">
                        <i className="bx bx-play"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.toggleVideo && (
          <div
            className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-fade mfp-ready wow fadeInDown"
            style={{ top: "56px", position: "absolute", height: "225px" }}>
            <div className="mfp-container mfp-iframe-holder">
              <div className="mfp-content">
                <div className="mfp-iframe-scaler">
                  <button
                    title="Close (Esc)"
                    type="button"
                    className="mfp-close"
                    onClick={this.handleHideVideoClick}>
                    ×
                  </button>
                  <iframe
                    className="mfp-iframe"
                    width="853"
                    height="480"
                    src={Video}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

export default Presentation;
